<template>
  <div class="d-flex justify-space-between align-center">
    <div :key="searchKey" class="search-bar" data-pendo-id="global-search-bar">
      <VueSuggestions
        :items="itemFiltered"
        v-model="item"
        :set-label="setLabel"
        :item-template="headerItemTemplate"
        @changed="debounceInputChange"
        @click="onClickHandle"
        @selected="itemSelected"
        @enter="debounceOnEnterKeyPress"
        ref="vuesuggestions"
        :placeholder="$t('globalSearchPlaceholder')"
        :disabled="isTrialExpired"
        :values="searchValue"
        :minLen="0"
      >
        <template #searchSlot>
          <v-icon
            class="icon"
            v-text="`mdi-close`"
            @click="clearSearch"
            name="global-cross-icon"
            color="#21a7e0"
            :disabled="isTrialExpired"
            v-show="crossButton"
          />
          <v-icon
            class="icon"
            v-text="`mdi-magnify`"
            @click="debounceOnEnterKeyPress"
            name="global-search-icon"
            :color="isSearchColor"
            :disabled="isTrialExpired"
            data-pendo-id="search-bar-cta"
          />
        </template>
      </VueSuggestions>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import he from 'he';
import DialogService from '../../services/DialogService';
import {
  performAutoComplete,
  // performHybridSearch,
  // userByCompany,
} from '../../graphql/queries';
import VueSuggestions from '../vue-suggestions.vue';
import headerItemTemplate from '../common/headerItemTemplate.vue';
import EventBus from '../common/event-bus';
import { AnalyticsHandler } from '../common/Analytics/AnalyticsHandler';
import { MatomoAnalyticsHandler } from '../common/Analytics/MatomoAnalyticsHandler';
import {
  addSearchTerms,
  updateSearchTerms,
  getUserSearchTerms,
  logSearchQuery,
  getPrezentationSearch,
} from '../../utils/api-helper';
import { getFileURL } from '@/utils/calyrex';
import { trackSearchEvents } from '../common/Analytics/SearchEvents';
import {
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_QUERY,
  TD_RECENT_OR_SUGGESTED_OR_NA,
} from '../common/Analytics/MatomoTrackingDataHelper';
import {
  getTypeSenseAPIKey,
  TypeSenseServer,
} from '../../pages/components/TypeSenseInit';
import helpApi from '../../API/help-api';
import { GraphQLService } from '../../services/GraphQLService';
import usersApi from '../../API/users-api';

const typesLocale = {
  S_QUICKTIP: 'Quick-tips',
  S_TUTORIAL: 'Tutorials',
  S_FAQ: 'FAQs',
  S_WALKTHROUGH: 'Walkthroughs',
};
const mapType = (t) => typesLocale[t] || 'Help';

export default {
  name: 'GlobalSearch',
  mixins: [DialogService],
  components: {
    VueSuggestions,
  },
  props: {
    updateContent: {
      type: Function,
      default: () => ({}),
    },
    updateMini: {
      type: Function,
      default: () => ({}),
    },
    minimize: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      searchValue: '',
      helpTypes: ['Tutorials', 'Quick-tips', 'Walkthroughs', 'FAQs'],
      searchKey: 0,
      // isCopied: false,
      // isSent: false,
      abortController: null, // Holds the AbortController instance
      crossButton: false,
      username: '',
      tsClient: '',
      tsKey: '',
      isSearchColor: 'gray',
      search_query: '',
      // search_bar: '',
      // isShown: false,
      // showMenu: false,
      // showShare: false,
      // menuPosition: {
      //   x: 0,
      //   y: 0,
      // },
      requestFingerPrint: {
        firstName: null,
        lastName: null,
        email: null,
      },
      currentScreen: 'request', // requestConfirmation ,share ,shareConfirmation,request
      audienceList: [],
      audience: null,
      item: {},
      itemFiltered: [],
      headerItemTemplate,
      searchResults: [],
      searchTerms: [],
      // allAudiencesForSearch: [],
      allPrezentations: [],
      eligibleWalkthroughs: [],
      // allOPTickets: [],
      opLimit: 3,
      onBoardingTutorial: [],
      notValidId: [],
      featureEnablementConfig: {
        Know: 'isKnowEnabled',
        'Overnight Prezentations': 'hasOPAccess',
        Uploader: 'getUploadAccessLevel',
        'Auto Generator': 'getGenerateAccessLevel',
        'Template Converter': 'isComplyEnabledWithAccess',
        Synthesis: 'isSynthesisFullAccess',
        Redesign: 'isRedesignFullAccess',
        Accelerators: 'isAcceleratorsEnabled',
        'ASTRID™ AI': 'checkAstridAccess',
        ASTRID: 'checkAstridAccess',
      },
    };
  },
  computed: {
    ...mapState('help', ['payloads']),
    ...mapState('knowStore', ['knowCategories']),
    ...mapState('tourStore', ['notifications']),
    ...mapState('users', [
      'currentUser',
      'isTrialExpired',
      'trialDaysLeft',
      'userAssociatedCompanies',
      'sharedPrivateAudienceListByUser',
      'sharedAudienceListByUser',
      'sharedUserListByUser',
      'themes',
      'storylines',
      'currentTheme',
      'startLoadingSlidesFlag',
    ]),
    ...mapGetters('users', [
      'isKnowEnabled',
      'getOPAccessLevel',
      'getUploadAccessLevel',
      'getGenerateAccessLevel',
      'isSynthesisFullAccess',
      'isRedesignFullAccess',
      'isAcceleratorsEnabled',
    ]),
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapGetters('slidesStore', [
      'getIsSlideDetailsDirty',
      'getIsSlideACLDirty',
      'getIsSlideBestPractisesDirty',
    ]),
    hasOPAccess() {
      return this.getOPAccessLevel && this.getOPAccessLevel !== 'no_access';
    },
    isComplyEnabledWithAccess() {
      const complyFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'comply',
      );
      return (
        (complyFeature &&
          complyFeature.enabled &&
          complyFeature.access_level &&
          complyFeature.access_level === 'full_access') ||
        (complyFeature &&
          complyFeature.access_level &&
          (complyFeature.access_level === 'limited_access' ||
            complyFeature.access_level === 'restricted_access'))
      );
    },
    // ...mapState("users", ["sessionKey"]),
    // ...mapState("learndata", ["moduleItems"]),
    // ...mapState('prezentations', ['allPrezentations']),

    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },
    checkAstridAccess() {
      const featuresToCheck = [
        'slide-generate',
        'user-prezentation-upload',
        'synthesis',
        'comply',
        'redesign',
      ];
      const features = this.currentUser?.features;
      return features.some(
        (feature) =>
          featuresToCheck.includes(feature.feature_name) &&
          feature.enabled &&
          (feature.access_level === 'full_access' ||
            feature.access_level === 'restricted_access'),
      );
    },
  },
  methods: {
    ...mapActions('users', [
      'performSearch',
      'addSearchTerms',
      'setCurrentUser',
    ]),
    ...mapActions('users', ['setSearchProgress']),
    ...mapActions('tourStore', ['setTour']),
    ...mapActions('search', ['updateRecentQueries']),
    // ...mapActions("learndata", ["loadLearningData"]),
    ...mapActions('tourStore', ['setTour', 'setNotifications']),
    ...mapActions('slidesStore', [
      'setRouteToNavigate',
      'saveSlidesStateSnapshot',
      'setSlideViewConfirmationModalState',
    ]),
    ...mapActions('prezentations', ['setAllPrezsForHybridSearch']),
    ...mapActions('bestPracticesExamples', [
      'setBPEviewType',
      'setHighlightedSlides',
    ]),
    async getWalkthroughs() {
      return await helpApi.methods.getHelpData('walkthroughs').then(
        (resp) => {
          const eligibleWalkthroughs = resp.data.filter(
            (wt) =>
              wt.role === 'all' ||
              wt.role.toLocaleLowerCase() ===
                this.currentUser.user.role.toLocaleLowerCase(),
          );
          return eligibleWalkthroughs;
        },
        (error) => {
          console.log(error.response);
        },
      );
    },
    getTheme() {
      const isNonBrandedCompany =
        this.currentUser?.company?.isNonBranded || false;
      const { hasBPGSlides } = this.currentUser?.theme || {};
      if (isNonBrandedCompany && hasBPGSlides) {
        return this.currentUser?.theme.code;
      }
      return this.currentUser?.company?.defaultTemplate;
    },
    async getOnboardingTutorialDetails() {
      return await helpApi.methods
        .getHelpData(
          'tutorial',
          this.currentUser?.user?.language || 'en',
          'Overview',
        )
        .then(
          (resp) => {
            this.onBoardingTutorial = resp?.data;
            this.notValidId = resp?.not_valid_id;
          },
          (error) => {
            console.log(error.response);
          },
        );
    },
    async searchOPTickets({ query, page }) {
      const searchParameters = {
        q: query,
        query_by:
          'name,storyline,audienceFullName,audienceFirstName,audienceLastName,owner',
        page,
        per_page: this.opLimit,
      };
      return this.tsClient
        .collections('op_tickets')
        .documents()
        .search(searchParameters)
        .then((response) => {
          const { found, hits, page: currentSearchPage } = response;
          this.OPSearchData = {
            found,
            currentSearchPage,
          };
          if (hits?.length) {
            return hits.map((tkt) => ({
              ...tkt?.document,
            }));
          }
          return [];
        });
    },

    // async loadLearnItems({ query }){
    //   try {
    //     const searchParameters
    //   }
    // },
    async loadOPTickets({ query, page }) {
      try {
        const searchParams = {
          query,
          page,
        };
        const searchResults = await this.searchOPTickets(searchParams);
        if (searchResults.length) return searchResults;
        return [];
      } catch (e) {
        console.log('error while loading op tickets', e);
        return [];
      }
    },

    currentRoute() {
      return this.$route.path;
    },

    clearSearch() {
      this.crossButton = false;
      this.item = null;
      this.searchValue = '';
      this.searchKey++;
      this.itemFiltered = [];
    },

    getProfileImage() {
      if (this.currentUser.user.profileImage) {
        getFileURL(
          null,
          `public/${this.currentUser.user.profileImage}`,
          null,
          'storage',
        )
          .then((s3obj) => {
            this.profileImage = s3obj;
          })
          .catch((err) => {
            console.log(err);
            this.profileImage = '/assets/img/home-header-profile.svg';
          });
      }
    },

    async logSearch(term) {
      try {
        await this.getSearchTerms();
        const index =
          this.searchTerms &&
          this.searchTerms.findIndex((item) => {
            const category = item.category.toLowerCase().replace('_', ' ');
            const termName = term.name.toLowerCase().replace('_', ' ');
            return category === termName;
          });
        if (index >= 0) {
          this.searchTerms[index].count += 1;
          const searchData = {
            id: this.searchTerms[index].id,
            count: this.searchTerms[index].count,
            searchTermResult: !(term === undefined),
          };
          updateSearchTerms(searchData)
            .then((value) => {
              console.log(value);
              this.getSearchTerms();
            })
            .catch((err) => console.log(err));
        } else {
          const searchData = {
            id: uuidv4(),
            userID: this.currentUser.user.id,
            searchTerm: term.display_name,
            category: term.name,
            count: 1,
            createdAt: new Date().toISOString(),
          };
          addSearchTerms(searchData)
            .then((value) => {
              console.log(value);
              this.addSearchTerms(value.data.createSearchTerm);
              this.getSearchTerms();
            })
            .catch((err) => console.log(err));
        }
      } catch (err) {
        console.log(err);
      }
    },

    makeNavigation(routePath, type, action, pushAction = false) {
      if (
        (this.getIsDirty === true ||
          this.getIsDirtyIcon === true ||
          this.getIsSlideDetailsDirty === true ||
          this.getIsSlideACLDirty === true ||
          this.getIsSlideBestPractisesDirty === true) &&
        (this.$route.name === 'slide-detail' || this.isBPLSlideView) &&
        this.startLoadingSlidesFlag &&
        this.themes &&
        this.themes.length > 0
      ) {
        this.setSlideViewConfirmationModalState(true);
        this.setRouteToNavigate({
          content: routePath,
          type,
          action,
          pushAction,
        });
      } else {
        this.saveSlidesStateSnapshot({});
        this.setRouteToNavigate({
          content: '',
          type: '',
          action: '',
          pushAction: false,
        });
        if (action === 'push') {
          if (pushAction === true) {
            this.$router
              .push(routePath, () => this.$router.go(0))
              .catch(() => {});
          } else {
            this.$router.push(routePath).catch(() => {});
          }
        } else if (action === 'replace') {
          this.$router.replace(routePath).catch(() => {});
        }
      }
    },

    doSearch(item) {
      this.minimize();
      if (this.$route.query && this.$route.query.query !== item.name) {
        this.makeNavigation(
          `/home/slides?category=${item.category ? item.category : ''}&query=${
            item.name
          }&origin=search`,
          'slides',
          'push',
        );
      }
      this.setSearchProgress(true);
      const payload = {};
      payload.username = this.currentUser.user.cognitoID;
      payload.category = item.category ? item.category : 'none';
      payload.query = item.name;
      payload.limit = 33;
      payload.skip = 0;
      this.saveSlidesStateSnapshot({});
      EventBus.$emit('DO_SEARCH', payload);
    },

    doKnowSearch(item) {
      this.minimize();
      this.makeNavigation(
        `/home/best-practice-library?category=${
          item.category ? item.category : item.item.name || ''
        }&query=${item.name}`,
        'best-practice-library',
        'push',
      );
      setTimeout(() => {
        EventBus.$emit('DO_KNOW_SEARCH', item);
      }, 1000);
    },

    onClickHandle(check) {
      if (check) {
        this.isSearchColor = '#21a7e0';
      } else {
        this.isSearchColor = 'gray';
      }
    },

    /**
     * Debounce the search by 300ms
     */
    // eslint-disable-next-line func-names
    debounceOnEnterKeyPress: _.debounce(function () {
      this.onEnterKeyPress();
    }, 300),

    async onEnterKeyPress() {
      if (!this.searchValue) return;
      // this.$refs.vuesuggestions.getsearchdata();
      // emit event to clear search count in search component
      // required when search is performed on same page
      // (component already mounted)
      EventBus.$emit('CLEAR_SEARCH_COUNT');
      this.makeNavigation(
        `/home/search?s=${this.searchValue}`,
        'hybrid-search',
        'push',
      );
      // this.$router.push(`/home/search?s=${this.searchValue}`).catch(() => {});
      this.item = {};
      this.item.name = this.searchValue;
      const payload = {
        type: 'All',
        value: this.searchValue,
        subvalue: this.searchValue,
        attributes: `/home/search?s=${this.searchValue}`,
        object: {},
      };
      this.updateRecentQueries(payload);
    },

    async itemSelected(item) {
      this.item = item;
      this.searchValue = item.name;
      this.crossButton = true;
      if (this.item.type === 'People' && this.item.item.isActive) {
        trackSearchEvents.trackDropDownPeople(this.currentUser, {
          [TD_QUERY]: this.searchValue,
          [TD_COMMON_COLUMN_NAME]: TD_RECENT_OR_SUGGESTED_OR_NA,
          [TD_COMMON_COLUMN_VALUE]: this.$refs.vuesuggestions.dropDownCategory,
        });
        if (this.item.item.isShared && this.item.item.type !== 'user') {
          const payload = {
            type: item.type,
            value: item.name,
            subvalue: item.name,
            attributes: `/home/fingerprints?uuid=${
              this.item.item.uuid
            }&log_search=true&display_name=${this.item.item.fullName}&type=${
              this.item.item.type === 'audience'
                ? 'audience'
                : 'private_audience'
            }`,
            object: item.item,
          };
          this.updateRecentQueries(payload);
          this.makeNavigation(
            `/home/fingerprints?uuid=${
              this.item.item.uuid
            }&log_search=true&display_name=${this.item.item.fullName}&type=${
              this.item.item.type === 'audience'
                ? 'audience'
                : 'private_audience'
            }`,
            'people',
            'push',
          );
          return;
        }
        if (this.item.item.type === 'user') {
          const payload = {
            type: item.type,
            value: item.name,
            subvalue: item.name,
            attributes: `/home/fingerprints?username=${this.item.item.id}&type=user&log_search=true&display_name=${this.item.item.fullName}`,
            object: item.item,
          };
          this.updateRecentQueries(payload);
          this.makeNavigation(
            `/home/fingerprints?username=${this.item.item.id}&type=user&log_search=true&display_name=${this.item.item.fullName}`,
            'people',
            'push',
          );
          return;
        }
        if (this.item.item.type === 'standard') {
          const payload = {
            type: item.type,
            value: item.name,
            subvalue: item.name,
            attributes: `/home/fingerprints?username=${this.item.item.id}&type=standard&log_search=true&display_name=${this.item.item.fullName}`,
            object: item.item,
          };
          this.updateRecentQueries(payload);
          this.makeNavigation(
            `/home/fingerprints?username=${this.item.item.id}&type=standard&log_search=true&display_name=${this.item.item.fullName}`,
            'people',
            'push',
          );
          return;
        }

        // for private aud, display_name has "(Private)" as suffix
        // and phaedrus username = "<Priv aud id>_<Priv aud userid>" so split this string to fetch the priv aud dynamodb id
        MatomoAnalyticsHandler.searchSelectPeople(
          this.item.item.name,
          this.currentUser,
        );
        // console.log('trackDropDownPeople');
        // trackSearchEvents.trackDropDownPeople(this.currentUser, {
        //   [TD_QUERY]: this.searchValue,
        //   [TD_RECENT_OR_SUGGESTED_OR_NA]: this.$refs.dropDownCategory,
        // });

        const { uuid, id } = this.item.item;
        const idField = uuid ? 'uuid' : 'username';
        const idFieldValue = uuid || id;

        if (this.item.item.audienceType === 'private_audience') {
          const payload = {
            type: item.type,
            value: item.name,
            subvalue: item.name,
            attributes: `/home/fingerprints?${idField ? `${idField}=` : ''}${
              idField ? `${idFieldValue}&` : ''
            }type=private_audience&log_search=true&display_name=${
              this.item.item.fullName
            }`,
            object: item.item,
          };
          this.updateRecentQueries(payload);
          this.makeNavigation(
            `/home/fingerprints?${idField ? `${idField}=` : ''}${
              idField ? `${idFieldValue}&` : ''
            }type=private_audience&log_search=true&display_name=${
              this.item.item.fullName
            }`,
            'people',
            'push',
          );
          return;
        }

        const payload = {
          type: item.type,
          value: item.name,
          subvalue: item.name,
          attributes: `/home/fingerprints?${idField ? `${idField}=` : ''}${
            idField ? `${idFieldValue}&` : ''
          }type=audience&log_search=true&display_name=${
            this.item.item.fullName
          }`,
          object: item.item,
        };
        this.updateRecentQueries(payload);
        this.makeNavigation(
          `/home/fingerprints?${idField ? `${idField}=` : ''}${
            idField ? `${idFieldValue}&` : ''
          }type=audience&log_search=true&display_name=${
            this.item.item.fullName
          }`,
          'people',
          'push',
        );

        AnalyticsHandler.selectedPeopleFromAutoComplete(
          this.currentUser,
          this.item.item.name,
        );
      } else if (this.item.type === 'Slides') {
        trackSearchEvents.searchDropdownSlides(this.currentUser, {
          [TD_QUERY]: this.searchValue,
          [TD_COMMON_COLUMN_NAME]: TD_RECENT_OR_SUGGESTED_OR_NA,
          [TD_COMMON_COLUMN_VALUE]: this.$refs.vuesuggestions.dropDownCategory,
        });
        const payload = {
          type: item.type,
          value: item.name,
          subvalue: item.name,
          attributes: `/home/slides?query=${item.name}&origin=search`,
          object: item.item,
        };
        this.updateRecentQueries(payload);
        this.search_query = this.item.category ? this.item.category : '';
        if (!this.search_query) {
          this.search_query = this.item.name;
          MatomoAnalyticsHandler.searchActualQuery(
            this.search_query,
            this.currentUser,
          );
        } else {
          this.logSearch(item.item);
          MatomoAnalyticsHandler.searchSelectIdea(
            this.item.name,
            this.currentUser,
          );
          AnalyticsHandler.performSlideSearch(this.currentUser, this.item.name);
        }
        this.doSearch(item);
      } else if (this.item.type === 'Slides (Uploads)') {
        const payload = {
          type: item.type,
          value: item.name,
          subvalue: item.name,
          attributes: `/home/slides?query=${item.item.id}&origin=search`,
          object: item.item,
        };
        const userData = {
          performIncrements: {
            countSearches: 1,
          },
        };
        usersApi.methods
          .updateUserProfile(userData)
          .then((updatedUserDetails) => {
            const userInfo = this.currentUser;
            userInfo.user = updatedUserDetails;
            this.setCurrentUser(userInfo);
          })
          .catch((err) =>
            console.log('error while updating the search count', err),
          );
        const logPayload = {
          type: 'Slide Library (Uploads)',
          searchTerm: this.searchValue,
          searchTermResult: true,
        };
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
        this.updateRecentQueries(payload);
        this.makeNavigation(
          `/home/slides/${item.item.id}&origin=search`,
          'slides',
          'push',
        );
      } else if (this.item.type === 'Learn') {
        const payload = {
          type: item.type,
          value: item.name,
          subvalue: item.name,
          attributes: `/home/slides?query=${item.name}&origin=search`,
          object: item.item,
        };
        this.updateRecentQueries(payload);
        if (item.item.stype === 'video') {
          MatomoAnalyticsHandler.searchLearn({
            title: item.item.title,
            origin: 'auto',
            isExact: this.searchValue === item.item.etags.lm_title,
            user: this.currentUser,
            searchKeyword: this.searchValue,
          });
          trackSearchEvents.trackDropDownLearn(this.currentUser, {
            [TD_QUERY]: this.searchValue,
            [TD_COMMON_COLUMN_NAME]: TD_RECENT_OR_SUGGESTED_OR_NA,
            [TD_COMMON_COLUMN_VALUE]:
              this.$refs.vuesuggestions.dropDownCategory,
          });
          this.makeNavigation(
            `/home/learn/course/${item.item.etags.lm_course_id}?assetid=${item.item.filename}&log_search=true&display_name=${item.name}`,
            'learn',
            'push',
          );
        } else if (item.item.stype === 'practice') {
          this.makeNavigation(
            `/home/learn/novice/practiceQuestions/${item.item.filename}/${item.item.etags.lm_course_id}?log_search=true&display_name=${item.name}`,
            'learn',
            'push',
          );
        }
      } else if (this.item.type === 'Prezentations') {
        const payload = {
          type: item.type,
          value: item.name,
          subvalue: item.name,
          attributes: `/home/slides?query=${item.name}&origin=search`,
          object: item.item,
        };
        await this.updateRecentQueries(payload);
        trackSearchEvents.trackDropDownPrezentations(this.currentUser, {
          [TD_QUERY]: this.searchValue,
          [TD_COMMON_COLUMN_NAME]: TD_RECENT_OR_SUGGESTED_OR_NA,
          [TD_COMMON_COLUMN_VALUE]: this.$refs.vuesuggestions.dropDownCategory,
        });
        if (this.currentRoute().includes('myPrezentation')) {
          this.makeNavigation(
            `/home/myPrezentation/${
              item.item.id
            }?log_search=true&display_name=${
              item.name ? item.name : item.item.name
            }`,
            'prezentations',
            'push',
            // true,
          );
        } else {
          this.makeNavigation(
            `/home/myPrezentation/${
              item.item.id
            }?log_search=true&display_name=${
              item.name ? item.name : item.item.name
            }`,
            'prezentations',
            'push',
          );
        }
      } else if (this.item.type === 'Best Practice Decks') {
        const payload = {
          type: item.type,
          value: item.name,
          subvalue: item.name,
          attributes: `/home/best-practice-library-decks/${item.item.id}`,
          object: item.item,
        };
        if (item.item.matchedIndice) {
          this.setHighlightedSlides(item.item.matchedIndice.split(', '));
        }
        await this.updateRecentQueries(payload);
        this.setBPEviewType('Decks');
        trackSearchEvents.trackDropDownPrezentations(this.currentUser, {
          [TD_QUERY]: this.searchValue,
          [TD_COMMON_COLUMN_NAME]: TD_RECENT_OR_SUGGESTED_OR_NA,
          [TD_COMMON_COLUMN_VALUE]: this.$refs.vuesuggestions.dropDownCategory,
        });
        if (this.currentRoute().includes('best-practice-library')) {
          this.makeNavigation(
            `/home/best-practice-library-decks/${item.item.id}?log_search=true`,
            'best-practice-library',
            'push',
            // true,
          );
        } else {
          this.makeNavigation(
            `/home/best-practice-library-decks/${item.item.id}?log_search=true`,
            'best-practice-library',
            'push',
          );
        }
      } else if (this.item.type && this.helpTypes.includes(this.item.type)) {
        if (
          this.currentRoute().includes(
            `help/${this.item.type.toLowerCase().replace('-', '')}`,
          )
        ) {
          const payload = {
            type: item.type,
            value: item.name,
            subvalue: item.name,
            attributes: `/help/id=${this.item.item.id.toLowerCase()}&log_search=true&display_name=${
              item.name
            }`,
            object: item.item,
          };
          this.updateRecentQueries(payload);
          this.makeNavigation(
            {
              query: {
                id: this.item.item.id.toLowerCase(),
                log_search: true,
                display_name: this.item.name,
              },
            },
            'help',
            'replace',
          );
        } else {
          MatomoAnalyticsHandler.trackHelp(
            {
              type: this.item.type.toLowerCase().replace('-', ''),
              isSearch: true,
              searchKey: this.item.name,
            },
            this.currentUser,
          );
          console.log('trackHelp');
          trackSearchEvents.trackHelp(this.currentUser, {
            type: this.item.type.toLowerCase().replace('-', ''),
            searchKey: this.searchValue,
            isSearch: true,
            searchType: 'auto',
            item,
            dropDownCategory: this.$refs.vuesuggestions.dropDownCategory,
          });
          const payload = {
            type: item.type,
            value: item.name,
            subvalue: item.name,
            attributes: `/help/${this.item.type
              .toLowerCase()
              .replace(
                '-',
                '',
              )}?id=${this.item.item.id.toLowerCase()}&log_search=true&display_name=${
              item.name
            }`,
            object: item.item,
          };
          this.updateRecentQueries(payload);
          // We removed the - from Quick-tips
          this.makeNavigation(
            `/help/${this.item.type
              .toLowerCase()
              .replace(
                '-',
                '',
              )}?id=${this.item.item.id.toLowerCase()}&log_search=true&display_name=${
              item.name
            }`,
            'help',
            'push',
          );
        }
      } else if (this.item.type === 'Best Practice Slides') {
        trackSearchEvents.trackDropdownKnow(this.currentUser, {
          [TD_QUERY]: this.searchValue,
          [TD_COMMON_COLUMN_NAME]: TD_RECENT_OR_SUGGESTED_OR_NA,
          [TD_COMMON_COLUMN_VALUE]: this.$refs.vuesuggestions.dropDownCategory,
        });
        const payload = {
          type: item.type,
          value: item.name,
          subvalue: item.name,
          attributes: `/home/best-practice-guides?category=${item.category}&query=${item.name}`,
          object: item.item,
        };
        this.updateRecentQueries(payload);
        this.doKnowSearch(item);
        this.setBPEviewType('Slides');
      } else if (this.item.type === 'Templates') {
        const payload = {
          type: item.type,
          value: item.name,
          subvalue: item.name,
          attributes: `/profile?tab=templates&query=${this.item.name}`,
          object: item.item,
        };
        this.updateRecentQueries(payload);
        this.makeNavigation(
          `/profile?tab=templates&query=${this.item.name}`,
          'templates',
          'push',
        );
      } else if (this.item.type === 'Storylines') {
        const payload = {
          type: item.type,
          value: item.name,
          subvalue: item.name,
          attributes: `/home/storyline/${this.item.item.storylineId}`,
          object: item.item,
        };
        const logPayload = {
          type: 'Storylines',
          searchTerm: this.searchValue,
          searchTermResult: true,
        };
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
        this.updateRecentQueries(payload);
        this.makeNavigation(
          `/home/storyline/${this.item.item.storylineId}`,
          'storylines',
          'push',
        );
      } else if (this.item.type === 'Build') {
        const payload = {
          type: item.type,
          value: item.name,
          subvalue: item.name,
          attributes: `/home/build?outline_id=${this.item.item.storylineId}`,
          object: item.item,
        };
        const logPayload = {
          type: 'Story Builder',
          searchTerm: this.searchValue,
          searchTermResult: true,
        };
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
        this.updateRecentQueries(payload);
        this.makeNavigation(
          `/home/build?outline_id=${this.item.item.storylineId}`,
          'build',
          'push',
        );
      } else if (this.item.type === 'Overnight Prezentations') {
        trackSearchEvents.trackDropDownPrezentations(this.currentUser, {
          [TD_QUERY]: this.searchValue,
          [TD_COMMON_COLUMN_NAME]: TD_RECENT_OR_SUGGESTED_OR_NA,
          [TD_COMMON_COLUMN_VALUE]: this.$refs.vuesuggestions.dropDownCategory,
        });
        if (this.currentRoute().includes('my-overnight-prezentation')) {
          const payload = {
            type: item.type,
            value: item.name,
            subvalue: item.name,
            attributes: `/home/my-overnight-prezentation/${this.item.item.id}`,
            object: item.item,
          };
          this.updateRecentQueries(payload);
          this.makeNavigation(
            `/home/my-overnight-prezentation/${this.item.item.id}`,
            'op',
            'push',
            true,
          );
        } else {
          const payload = {
            type: item.type,
            value: item.name,
            subvalue: item.name,
            attributes: `/home/my-overnight-prezentation/${this.item.item.id}`,
            object: item.item,
          };
          this.updateRecentQueries(payload);
          this.makeNavigation(
            `/home/my-overnight-prezentation/${this.item.item.id}`,
            'op',
            'push',
          );
        }
      } else if (this.item.type === 'Generate') {
        const logPayload = {
          type: 'Auto Generator',
          searchTerm: this.searchValue,
          searchTermResult: true,
        };
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
        const payload = {
          type: item.type,
          value: item.name,
          subvalue: item.name,
          attributes: `/home/generate?prompt=${item.name}`,
          object: item.item,
        };
        this.updateRecentQueries(payload);
        this.$router.push(`/home/generate?prompt=${item.name}`);
      } else if (this.item.type === 'All') {
        const payload = {
          type: this.item.type,
          value: this.item.value,
          subvalue: this.item.subvalue,
          attributes: this.item.attributes,
          object: {},
        };
        this.updateRecentQueries(payload);
        this.$router.push(this.item.attributes);
      }
      EventBus.$emit('CLEAR_SLIDE_LIBRARY_SEARCH');
      EventBus.$emit('CLEAR_BPE_LIBRARY_SEARCH');
    },

    serializeParam(params) {
      return Object.entries({ ...params })
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    },

    setLabel(item) {
      return item.name;
    },

    /**
     * Debounce the typehaead queries by 300ms
     */
    // eslint-disable-next-line func-names
    debounceInputChange: _.debounce(function (searchText) {
      this.inputChange(searchText);
      if (searchText.length) {
        this.crossButton = true;
      } else {
        this.crossButton = false;
      }
    }, 300),
    getUploadedSlides(text) {
      const params = {
        username: this.currentUser.user.cognitoID,
        query: text,
        companies: `${this.currentUser.company.name.toLowerCase()},generic`,
        limit: 3,
        vaultmode: true,
        type: 'uploaded',
        theme:
          this.currentTheme?.code || this.currentUser?.theme?.code || 'default',
      };
      const serialized2 = this.serializeParam(params);
      return GraphQLService.requestWithToken(
        graphqlOperation(performAutoComplete, {
          parameters: serialized2,
        }),
      )
        .then((res) => {
          const uploadedSlides = [];
          const result = JSON.parse(res.data.performAutoComplete);
          console.log(result);
          if (result.statusCode === 200 && result.body.payloads) {
            this.searchResults = result.body.payloads;
            const sortedMatch = result.body.payloads;
            sortedMatch.forEach(async (item) => {
              if (item.keytype === 'K_CATEGORY') {
                uploadedSlides.push({
                  name: item.display_name,
                  type: 'Slides (Uploads)',
                  category: item.name,
                  item,
                });
              }
            });
          }
          return uploadedSlides;
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
    },
    getSlidesAndBPEs(text, isKnow) {
      const theme = this.getTheme();
      const params = {
        username: this.currentUser.user.cognitoID,
        query: text,
        companies: `${this.currentUser.company.name.toLowerCase()},generic`,
        limit: 2,
        is_know: isKnow,
        vaultmode: true,
        type: 'keywords',
        theme: isKnow
          ? theme
          : this.currentTheme?.code ||
            this.currentUser?.theme?.code ||
            'default',
      };
      const serialized = this.serializeParam(params);
      return GraphQLService.requestWithToken(
        graphqlOperation(performAutoComplete, {
          parameters: serialized,
        }),
      )
        .then((res) => {
          this.itemFiltered = [];
          const slides = [];
          const bpe = [];
          const result = JSON.parse(res.data.performAutoComplete);
          if (result.statusCode === 200 && result.body.payloads) {
            this.searchResults = result.body.payloads;
            const payload = result.body.payloads;
            const sortedMatch = payload;
            sortedMatch.forEach(async (item) => {
              if (item.keytype === 'K_CATEGORY') {
                if (item.slide_type === 'brand_slides' && isKnow === 'no') {
                  slides.push({
                    name: item.display_name,
                    type: 'Slides',
                    category: item.name,
                    item,
                  });
                }
                if (
                  this.knowCategories.indexOf(item.name) >= 0 &&
                  this.isKnowEnabled &&
                  isKnow === 'yes'
                ) {
                  bpe.push({
                    name: item.display_name,
                    type: 'Best Practice Slides',
                    category: item.name,
                    item,
                  });
                }
              }
            });
          }
          return [...slides, ...bpe];
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
    },
    async getBestPracticesExamplesDecksSearch(query, signal) {
      const searchParameters = {
        query: query || '*',
        filters: {
          and: {
            isBestPracticeDeck: 'true',
          },
          or: {},
        },
        page: 1,
        limit: 3,
        is_dropdown_search: 1,
      };
      return getPrezentationSearch(searchParameters, this.tsKey, signal).then(
        (response) => {
          this.totalDecks = response?.data?.result?.length;
          if (response?.data?.result?.length) {
            return response.data.result.map((tkt) => ({
              item: {
                ...tkt?.prezentation,
                num_id: tkt?.prezentation.id,
                id: tkt?.prezentation.prezentationId,
                is_vector: tkt?.is_vector_search,
                sections: JSON.parse(tkt?.prezentation?.sections),
                matchedIndice: this.matchedSlideIndices({
                  ...tkt?.prezentation,
                  is_vector: tkt?.is_vector_search,
                }),
              },
              name: tkt?.prezentation.name,
              type: 'Best Practice Decks',
            }));
          }
          return [];
        },
      );
    },
    matchedSlideIndices(prezentation) {
      if (
        !prezentation.is_vector &&
        prezentation.slides &&
        prezentation.slide_ids
      ) {
        const hasInvalidField = prezentation.slides.some((slideItem) =>
          slideItem.highlights.some(
            (highlight) =>
              highlight.field.includes('name') ||
              highlight.field.includes('description') ||
              highlight.field === 'alias' ||
              highlight.field.includes('storyline'),
          ),
        );

        // If any invalid field exists, return an empty list
        if (hasInvalidField) {
          return '';
        }
        return JSON.parse(prezentation?.sections)
          .list[0]?.slides.map((slideItem, index) =>
            prezentation.slide_ids.includes(slideItem.uniqueID)
              ? index + 1
              : -1,
          )
          .filter((index) => index !== -1)
          .join(', ');
      }
      return '';
    },
    getMultipleDatas(text) {
      const searches = [
        {
          collection: 'audiences',
          q: text,
          query_by: 'firstName,lastName,email',
          per_page: 1,
        },
        {
          collection: 'private_audiences',
          q: text,
          query_by: 'firstName,lastName,email',
          filter_by: 'hasFingerPrint:=1',
          per_page: 1,
        },
        {
          collection: 'users',
          q: text,
          query_by: 'firstName,lastName,email',
          per_page: 1,
        },
        {
          collection: 'standard_audiences',
          q: text,
          query_by: 'firstName,lastName,email',
          per_page: 1,
        },
        {
          collection: 'learn',
          q: text,
          query_by: 'title, industry',
          per_page: 3,
        },
        // {
        //   collection: 'help',
        //   q: text,
        //   query_by: 'hm_extn, hm_name, hm_desc, title, industry',
        //   per_page: 3,
        //   filter_by: `language:=[all, ${this.currentUser.user.language}]`,
        // },
        {
          collection: 'help',
          q: text,
          query_by: 'hm_extn,hm_name,hm_desc,title, industry, summary',
          per_page: 250,
          filter_by: `language:=[all, ${this.currentUser.user.language}] && id :!=[${this.notValidId}]`,
        },
        {
          collection: 'prezentations',
          q: text,
          query_by: 'name, type, ownerID, ownerName',
          per_page: 3,
          filter_by: 'isBestPracticeDeck:false',
        },
        // {
        //   collection: 'prezentations',
        //   q: text,
        //   query_by: 'name, type, ownerID, ownerName, description, alias',
        //   per_page: 3,
        //   filter_by: 'isBestPracticeDeck:true',
        // },
        {
          collection: 'storylines',
          q: text,
          query_by:
            'type, description, sectionsName, extension, fullName, userID',
          filter_by: `storyType:=[${this.currentUser.user.storyPreference[0]}, all]`,
          per_page: 3,
        },
      ];
      return this.tsClient.multiSearch
        .perform({
          searches,
        })
        .then(({ results }) => {
          const itemFiltered = [];
          searches.forEach(({ collection }, index) => {
            const searchResults = results[index];
            if (collection === 'prezentations') {
              this.allPrezentations = this.setAllPrezsForHybridSearch([
                ...searchResults.hits.map((ele) => ele.document),
              ]);
            }
            searchResults.hits.forEach((audience) => {
              const data = this.mapTypesenceAudience(
                audience,
                collection,
                this.currentUser.user.companyID,
                this.currentUser.user.id,
              );
              if (data && data.length) {
                itemFiltered.push(...data);
              } else if (data) {
                itemFiltered.push(data);
              }
            });
          });
          return itemFiltered;
        })
        .catch(() => []);
    },
    getOPTickets(text) {
      if (this.hasOPAccess) {
        return this.loadOPTickets({ query: text, page: 1 })
          .then((res) => {
            const opTicketsFiltered = [];
            for (let i = 0; i < res.length; i++) {
              opTicketsFiltered.push({
                name: res[i].name,
                type: 'Overnight Prezentations',
                item: res[i],
              });
            }
            return opTicketsFiltered;
          })
          .catch(() => []);
      }
      return [];
    },
    async inputChange(text) {
      this.searchValue = text;
      if (this.abortController) {
        this.abortController.abort(); // Cancel previous request
      }

      // Create a new AbortController instance
      this.abortController = new AbortController();
      const { signal } = this.abortController;
      console.log('searchValue', this.searchValue);
      this.itemFiltered = [];
      if (text && text.length > 1) {
        const [
          uploadedSlides,
          slidesNonBPE,
          slidesBPE,
          multipleData,
          OPTickets,
          bpeDecks,
        ] = await Promise.all([
          this.getUploadedSlides(text),
          this.getSlidesAndBPEs(text, 'no'),
          this.getSlidesAndBPEs(text, 'yes'),
          this.getMultipleDatas(text),
          this.getOPTickets(text),
          this.getBestPracticesExamplesDecksSearch(text, signal),
        ]);
        this.itemFiltered.push(
          ...slidesNonBPE,
          ...slidesBPE,
          ...uploadedSlides,
          ...multipleData,
          ...OPTickets,
          ...bpeDecks,
        );
        const filteredItems = this.themes.filter((theme) =>
          theme.name.toLowerCase().includes(this.searchValue.toLowerCase()),
        );
        if (filteredItems.length) {
          const filteredLength =
            filteredItems.length >= 3 ? 3 : filteredItems.length;
          for (let i = 0; i < filteredLength; i++) {
            this.itemFiltered.push({
              name: filteredItems[i].name,
              type: 'Templates',
              category: filteredItems[i].code,
              item: filteredItems[i],
            });
          }
        }
        this.updateFilteredItems();
        const inputItem = {
          name: text,
          type: 'Slides',
          category: '',
        };
        const genItem = {
          name: text,
          type: 'Generate',
          category: '',
        };
        const slideIndex = this.itemFiltered.findIndex(
          (item) =>
            item &&
            item.name &&
            item.name.toLowerCase() === inputItem.name.trim().toLowerCase() &&
            item.type === 'Slides',
        );
        if (slideIndex === -1) {
          if (this.getGenerateAccessLevel) {
            this.itemFiltered.splice(0, 0, genItem);
          }
          this.itemFiltered.splice(0, 0, inputItem);
        } else if (this.getGenerateAccessLevel) {
          if (slideIndex === this.itemFiltered.length - 1) {
            this.itemFiltered.push(genItem);
          } else {
            this.itemFiltered.splice(slideIndex + 1, 0, genItem);
          }
        }
      }
    },
    updateFilteredItems() {
      const filteredItemsCategory = {
        people: [],
        slides: [],
        bp: [],
        templates: [],
        learn: [],
        help: [],
        prezentations: [],
        op: [],
        build: [],
        storylines: [],
      };

      this.itemFiltered.forEach((ele) => {
        if (ele.type === 'People') {
          filteredItemsCategory.people.push(ele);
        } else if (
          ele.type.includes('Slides') &&
          !ele.type.includes('Best Practice') &&
          ele.category !== ''
        ) {
          filteredItemsCategory.slides.push(ele);
        } else if (ele.type.includes('Best Practice')) {
          filteredItemsCategory.bp.push(ele);
        } else if (ele.type === 'Templates') {
          filteredItemsCategory.templates.push(ele);
        } else if (ele.type === 'Learn') {
          filteredItemsCategory.learn.push(ele);
        } else if (
          ele.type === 'Tutorials' ||
          ele.type === 'Walkthroughs' ||
          ele.type === 'FAQs' ||
          ele.type === 'Quick-tips'
        ) {
          filteredItemsCategory.help.push(ele);
        } else if (ele.type === 'Prezentations') {
          filteredItemsCategory.prezentations.push(ele);
        } else if (ele.type === 'Overnight Prezentations') {
          filteredItemsCategory.op.push(ele);
        } else if (ele.type === 'Build') {
          filteredItemsCategory.build.push(ele);
        } else if (ele.type === 'Storylines') {
          filteredItemsCategory.storylines.push(ele);
        }
      });

      const newItems = [];
      newItems.push(this.itemFiltered[0]);
      newItems.push(this.itemFiltered[1]);
      for (const [key] of Object.entries(filteredItemsCategory)) {
        filteredItemsCategory[key].forEach((ele) => {
          // To Avoid duplicates of itemFiltered 0 & 1
          if (
            (this.itemFiltered[0]?.type !== ele?.type ||
              this.itemFiltered[0]?.name !== ele?.name) &&
            (this.itemFiltered[1]?.type !== ele?.type ||
              this.itemFiltered[1]?.name !== ele?.name)
          ) {
            newItems.push(ele);
          }
        });
      }

      this.itemFiltered = newItems;
    },
    mapTypesenceAudience(audience, collection, companyId, userId) {
      const { document } = audience;
      const createdByMe = document.ownerID === userId;
      switch (collection) {
        case 'users': {
          const userType =
            document.companyID === companyId ? 'user' : 'shared_user';
          const audienceId = `${document.email}-${userType}`;
          document.isPublicLimited = !(
            document?.accessible_to?.includes('a__0') ||
            document?.accessible_to?.includes(
              `c__${this.currentUser.company.num_id}`,
            )
          );

          const fullName =
            document.email === userId
              ? `${document.fullName} (Me)`
              : document.fullName;

          return {
            name: fullName,
            type: 'People',
            item: {
              ...document,
              fullName,
              isPrivate: createdByMe,
              audienceId,
              id: document.email,
              num_id: Number(document.id),
              audienceType: 'user',
            },
          };
        }
        case 'audiences': {
          const audienceType =
            document.companyID === companyId ? 'audience' : 'shared_audience';
          const audienceId = `${document.email}-${audienceType}`;
          document.isPublicLimited = !(
            document?.accessible_to?.includes('a__0') ||
            document?.accessible_to?.includes(
              `c__${this.currentUser.company.num_id}`,
            )
          );

          return {
            name: document.fullName,
            type: 'People',
            item: {
              ...document,
              audienceId,
              isPrivate: createdByMe,
              num_id: Number(document.id),
              audienceType: 'audience',
              id: document.email,
            },
          };
        }
        case 'private_audiences': {
          const privateAudienceType = createdByMe
            ? 'private_audience'
            : 'shared_private_audience';
          const audienceId = `${document.email}-${privateAudienceType}`;
          document.isPublicLimited = !(
            document?.accessible_to?.includes('a__0') ||
            document?.accessible_to?.includes(
              `c__${this.currentUser.company.num_id}`,
            )
          );

          return {
            name: document.fullName,
            type: 'People',
            item: {
              ...document,
              audienceId,
              isGeneratedEmail: document.emailEntered === false,
              num_id: Number(document.id),
              audienceType: 'private_audience',
              isPrivate: createdByMe,
              id: document.email,
              isGroup: document.privateAudienceType === 'Group',
              fingerprintType: document.subType,
              creator: createdByMe ? null : document.ownerName,
            },
          };
        }
        case 'standard_audiences': {
          const audienceId = `${document.email}-standard`;

          return {
            name: document.fullName,
            type: 'People',
            item: {
              ...document,
              audienceId,
              num_id: Number(document.id),
              audienceType: 'standard',
              fingerprintType: 'Standard',
              isPrivate: createdByMe,
              id: document.email,
              creator: 'Prezent',
              profileImage: '/assets/img/standard-audience-dropdown-icon.svg',
            },
          };
        }
        case 'learn': {
          return {
            item: document,
            type: 'Learn',
            name: he.decode(document.etags.lm_title),
          };
        }
        case 'help': {
          if (document.stype === 'S_WALKTHROUGH') {
            const feature =
              this.featureEnablementConfig[document.etags.hm_name];
            const eligibleWalkthrough = this.eligibleWalkthroughs.find(
              (wt) => wt.uuid.toLowerCase() === document.filename.toLowerCase(),
            );
            console.log(this.eligibleWalkthroughs);
            if (eligibleWalkthrough && (!feature || this[feature])) {
              return {
                item: document,
                name: document.title,
                stype: mapType(document.stype),
                type: mapType(document.stype),
              };
            }
            return null;
          }
          if (document.stype === 'S_FAQ' || document.stype === 'S_TUTORIAL') {
            const feature = this.featureEnablementConfig[document.etags.hm_cat];

            if (!feature || this[feature]) {
              return {
                item: document,
                name: document.title,
                stype: mapType(document.stype),
                type: mapType(document.stype),
              };
            }
          } else {
            return {
              item: document,
              name: document.title,
              stype: mapType(document.stype),
              type: mapType(document.stype),
            };
          }
          return null;
        }
        case 'prezentations': {
          if (document.isBestPracticeDeck) {
            return {
              item: {
                ...document,
                num_id: document.id,
                id: document.prezentationId,
              },
              name: document.name,
              type: 'Best Practice Decks',
            };
          }
          return {
            item: {
              ...document,
              num_id: document.id,
              id: document.prezentationId,
            },
            name: document.name,
            type: 'Prezentations',
          };
        }
        case 'storylines': {
          if (
            document.type === 'Custom' &&
            document.isPrezentGenerated === true
          ) {
            return null;
          }
          const storylines = [
            {
              name: document.type,
              type: 'Build',
              category: 'storylines',
              item: document,
            },
          ];
          storylines.push({
            name: document.type,
            type: 'Storylines',
            category: 'storylines',
            item: document,
          });
          if (document.isPrezentGenerated) {
            storylines[0].creator = 'Prezent';
            storylines[1].creator = 'Prezent';
          }
          return storylines;
        }
        default:
          return null;
      }
    },
    getMatchingSharedPrivateAudienceList(text) {
      return [
        ...this.sharedPrivateAudienceListByUser,
        ...this.sharedAudienceListByUser,
        ...this.sharedUserListByUser,
      ].filter(
        (aud) =>
          aud.fullName.toLocaleLowerCase().includes(text.toLocaleLowerCase()) &&
          aud.fingerPrint,
      );
    },

    beforeClose(event) {
      if (!event.params?.close) {
        event.cancel();
      }
    },

    async getSearchTerms() {
      getUserSearchTerms()
        .then((searchData) => {
          const terms = searchData.data.searchByUser.items;
          if (terms.length) {
            this.searchTerms = terms;
          }
        })
        .catch((err) => console.log(err));
      // this.searchTermss = this.searchTerms;
    },
  },
  async beforeMount() {
    this.getWalkthroughs()
      .then((res) => {
        this.eligibleWalkthroughs = res;
      })
      .catch((err) => console.log(err));
    this.getOnboardingTutorialDetails();
    this.getProfileImage();
    this.tsClient = await TypeSenseServer.getClient();
    this.tsKey = await getTypeSenseAPIKey();
  },
  beforeDestroy() {
    EventBus.$off('CLEAR_SEARCH', this.clearSearch);
    EventBus.$off('PROFILE_IMAGE', this.getProfileImage);
    EventBus.$off('MINIMIZE_LEFTNAV', this.minimize);
    if (this.abortController) {
      this.abortController.abort();
    }
  },
  async mounted() {
    this.searchValue = this.$route.query.s;
    if (this.$route.query && this.$route.query.origin === 'search') {
      this.searchValue = this.$route.query.query;
    }
    await this.getSearchTerms();
    EventBus.$on('CLEAR_SEARCH', this.clearSearch);
    EventBus.$on('PROFILE_IMAGE', this.getProfileImage);
    EventBus.$on('MINIMIZE_LEFTNAV', this.minimize);
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
::v-deep {
  .search-bar {
    width: 560px;
    z-index: 3;
  }
}
</style>
