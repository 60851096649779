<template>
  <div :class="step == 2 ? 'modal-pad modal-container' : 'modal-container'">
    <v-btn
      :class="step == 2 ? 'close-btn close-step-2' : 'close-btn'"
      icon
      @click="handleClose"
    >
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div>
      <span class="add-user-title">{{
        step != 3 ? $t('prezentationsList.addAPresentation') : ''
      }}</span>
    </div>
    <div v-if="step === 1">
      <v-container>
        <v-row class="image-container">
          <img
            class="w-100 img-btn"
            src="/assets/img/prezentations/upload_prezentation.svg"
            alt
          />
        </v-row>
      </v-container>
      <div justify="center" align="center" class="upload-description">
        <div>{{ $t('prezentationsList.provideAPresentation') }}</div>
        <div class="description2">
          {{ $t('prezentationsList.reviewAndUploadPresentation') }}
        </div>
      </div>
      <div class="float-right send-btn">
        <v-btn
          class="primary-button mt-2 py-2"
          rounded
          :color="'#21a7e0'"
          button-height="32px"
          min-width="126px"
          @click="continueToNext"
        >
          {{ $t('common.continue') }}
        </v-btn>
      </div>
    </div>
    <div v-if="step === 2">
      <v-form
        ref="form"
        v-model="isNotValid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-container class="email-list-container">
          <v-row>
            <v-col class="name-col">
              <div class="message-label">
                {{ $t('prezentationsList.typeOfStoryline') }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="role-col">
              <div class="presentation-type-combobox-wrapper">
                <v-autocomplete
                  class="autocomplete-wrapper"
                  v-model="result"
                  :items="presentTypeList"
                  :placeholder="$t('prezentationsList.createCustomStoryline')"
                  return-object
                  item-text="type"
                  id="presentation-type-autocomplete-list"
                  :search-input.sync="search"
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  @change="updateDescription(result)"
                  required
                >
                  <template v-slot:no-data>
                    <v-list-item dense class="no-results">
                      <v-list-item-content>
                        <v-list-item-title
                          class="presentation-type-content--auto"
                        >
                          {{ $t('build.step1.storylineFieldNoResult') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item
                      v-on="on"
                      v-bind="attrs"
                      two-line
                      class="story-line-list"
                    >
                      <v-list-item-content
                        class="story-line-list-content"
                        v-if="
                          !(item.type === 'Custom' && item.isPrezentGenerated)
                        "
                        id="presentation-type-content--auto"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="option-wrapper"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <div class="prez-heading__wrapper">
                                <v-list-item-title
                                  class="prez-heading"
                                  v-if="
                                    !item.isPrezentGenerated && item.isOwner
                                  "
                                >
                                  {{ item.type }} |
                                  {{ $t('build.step1.createdBy') }}
                                  {{
                                    !item.isOwner
                                      ? item.fullName
                                      : $t('build.step1.me')
                                  }}
                                  |
                                  {{
                                    item.shared
                                      ? $t('build.step1.shared')
                                      : $t('build.step1.private')
                                  }}
                                  <NewFeatureBadge
                                    module-name="build"
                                    :feature-id="'build_storyline_dropdown_id'"
                                    :offsetX="'-4'"
                                    :offsetY="'4'"
                                    :size="'medium'"
                                    v-if="item.isNew"
                                    :content="'common.newContent'"
                                  >
                                  </NewFeatureBadge>
                                </v-list-item-title>
                                <v-list-item-title
                                  class="prez-heading"
                                  v-else-if="
                                    !item.isPrezentGenerated && !item.isOwner
                                  "
                                >
                                  {{ item.type }} |
                                  {{ $t('build.step1.createdBy') }}
                                  {{
                                    !item.isOwner
                                      ? item.fullName
                                      : $t('build.step1.me')
                                  }}
                                  <NewFeatureBadge
                                    module-name="build"
                                    :feature-id="'build_storyline_dropdown_id'"
                                    :offsetX="'-4'"
                                    :offsetY="'4'"
                                    :size="'medium'"
                                    :content="'common.newContent'"
                                    v-if="item.isNew"
                                  >
                                  </NewFeatureBadge>
                                </v-list-item-title>
                                <v-list-item-title class="prez-heading" v-else>
                                  {{ item.type }}
                                  <img
                                    src="/assets/img/prezent_avatar.svg"
                                    alt
                                    width="22px"
                                  />
                                  <NewFeatureBadge
                                    module-name="build"
                                    :feature-id="'build_storyline_dropdown_id'"
                                    :offsetX="'-4'"
                                    :offsetY="'4'"
                                    :size="'medium'"
                                    :content="'common.newContent'"
                                    v-if="item.isNew"
                                  >
                                  </NewFeatureBadge>
                                </v-list-item-title>
                                <v-list-item-subtitle class="prez-description">
                                  {{ item.description }}
                                </v-list-item-subtitle>
                              </div>
                            </div>
                          </template>
                          <div
                            :class="`option-tooltip-wrapper${
                              item.description.length > 50 ? '__small' : ''
                            }`"
                          >
                            <div>
                              <v-list-item-title
                                class="prez-heading"
                                v-if="!item.isPrezentGenerated && item.isOwner"
                              >
                                {{ item.type }} |
                                {{ $t('build.step1.createdBy') }}
                                {{
                                  !item.isOwner
                                    ? item.fullName
                                    : $t('build.step1.me')
                                }}
                                |
                                {{
                                  item.shared
                                    ? $t('build.step1.shared')
                                    : $t('build.step1.private')
                                }}
                                <NewFeatureBadge
                                  module-name="build"
                                  :feature-id="'build_storyline_dropdown_id'"
                                  :offsetX="'-4'"
                                  :offsetY="'4'"
                                  :size="'medium'"
                                  :content="'common.newContent'"
                                  v-if="item.isNew"
                                >
                                </NewFeatureBadge>
                              </v-list-item-title>
                              <v-list-item-title
                                class="prez-heading"
                                v-else-if="
                                  !item.isPrezentGenerated && !item.isOwner
                                "
                              >
                                {{ item.type }} |
                                {{ $t('build.step1.createdBy') }}
                                {{
                                  !item.isOwner
                                    ? item.fullName
                                    : $t('build.step1.me')
                                }}
                                <NewFeatureBadge
                                  module-name="build"
                                  :feature-id="'build_storyline_dropdown_id'"
                                  :offsetX="'-4'"
                                  :offsetY="'4'"
                                  :size="'medium'"
                                  :content="'common.newContent'"
                                  v-if="item.isNew"
                                >
                                </NewFeatureBadge>
                              </v-list-item-title>
                              <v-list-item-title class="prez-heading" v-else>
                                {{ item.type }}
                                <img
                                  src="/assets/img/prezent_avatar.svg"
                                  alt
                                  width="22px"
                                />
                                <NewFeatureBadge
                                  module-name="build"
                                  :feature-id="'build_storyline_dropdown_id'"
                                  :offsetX="'-4'"
                                  :offsetY="'4'"
                                  :size="'medium'"
                                  :content="'common.newContent'"
                                  v-if="item.isNew"
                                >
                                </NewFeatureBadge>
                              </v-list-item-title>
                              <v-list-item-subtitle class="prez-description">
                                {{ item.description }}
                              </v-list-item-subtitle>
                            </div>
                          </div>
                        </v-tooltip>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template #append-item>
                    <v-list-item
                      class="prezentaion-list-item--add"
                      @click="handleCustomSelectClick"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <div>
                            <v-icon
                              color="#20a7e0"
                              class="add-icon"
                              v-text="`mdi-plus`"
                            />
                          </div>
                          <div class="option-wrapper">
                            <div>
                              <v-list-item-title class="prez-heading">
                                {{ $t('build.step1.custom') }}
                              </v-list-item-title>
                              <v-list-item-subtitle class="prez-description">
                                {{ $t('build.step1.customSubtitle') }}
                              </v-list-item-subtitle>
                            </div>
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="name-col">
              <div class="message-label">
                {{ $t('prezentationsList.selectATemplate') }}*
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="role-col colleague-role">
              <div
                class="presentation-type-combobox-wrapper"
                data-pendo-id="slides-template-selector"
              >
                <v-autocomplete
                  class="autocomplete-wrapper"
                  :items="filteredThemes"
                  :placeholder="$t('profile.bulkActionModal.selectOne')"
                  return-object
                  item-text="name"
                  :attach="true"
                  :search-input.sync="templateSearch"
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  @change="(template) => handleClickTheme(template)"
                  required
                >
                  <template v-slot:item="{ item }">
                    <div class="template-list-item">
                      <div class="template-header">
                        <v-tooltip
                          top
                          max-width="200"
                          content-class="tooltip-content"
                          fixed
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="template-title"
                              v-on="on"
                              v-bind="attrs"
                            >
                              {{ item.name }}
                            </div>
                          </template>
                          <span>{{ item.name }}</span>
                        </v-tooltip>
                      </div>
                      <v-row class="mb-2">
                        <v-col class="available-theme-img">
                          <img :src="item.thumbnailURL" alt />
                        </v-col>
                        <v-col class="example-slide-img">
                          <img
                            :src="
                              item.sampleSlide
                                ? item.sampleSlideURL
                                : item.thumbnailURL
                            "
                            alt
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <div class="no-templates-fount">
                      <p class="no-result-text">
                        {{ $t('build.step1.storylineFieldNoResult') }}
                      </p>
                    </div>
                  </template>
                </v-autocomplete>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="textarea-container">
              <div class="message-label">
                {{ $t('build.step3.description') }}*
              </div>
              <div class="colleague-message">
                {{ $t('prezentationsList.helpYourColleague') }}
              </div>
              <div class="textarea">
                <textarea
                  v-model="presentationDetails.description"
                  required
                  :disabled="presentationDetails.storyline != 'Custom'"
                  :placeholder="$t('prezentationsList.example')"
                />
              </div>
            </v-col>
          </v-row>
          <v-row class="question-container">
            <v-col class="name-col">
              <div class="message-label">
                {{ $t('prezentationsList.sanitizeYourPresentation') }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-radio-group
                class="similar-slides-radiobtns"
                v-model="presentationDetails.sanitizationType"
                required
              >
                <v-row>
                  <v-col cols="12">
                    <v-radio
                      value="No Sanitization"
                      color="#20a7e0"
                      :class="
                        presentationDetails.sanitizationType ==
                        'No Sanitization'
                          ? 'radio-background radio-class'
                          : 'radio-class'
                      "
                    >
                      <template v-slot:label>
                        <v-row>
                          <v-col class="col-2 image-container">
                            <img
                              class="image-invite"
                              src="/assets/img/prezentations/no_sanitization.svg"
                              alt
                              width="100%"
                              height="68.5px"
                            />
                          </v-col>
                          <v-col class="col-10">
                            <div class="no-sanitization">
                              {{ $t('upload.noAnonymization') }}
                            </div>
                            <div class="no-sanitization-label">
                              {{ $t('upload.noAnonymizationText') }}
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                    </v-radio>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-radio
                      value="Partial Sanitization"
                      color="#20a7e0"
                      :class="
                        presentationDetails.sanitizationType ==
                        'Partial Sanitization'
                          ? 'radio-background radio-class'
                          : 'radio-class'
                      "
                    >
                      <template v-slot:label>
                        <v-row>
                          <v-col class="col-2 image-container">
                            <img
                              class="image-invite"
                              src="/assets/img/prezentations/partial_sanitization.svg"
                              alt
                            />
                          </v-col>
                          <v-col class="col-10">
                            <div class="no-sanitization">
                              {{ $t('upload.partialAnonymization') }}
                            </div>
                            <div class="no-sanitization-label">
                              {{ $t('upload.partialAnonymizationText') }}
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                    </v-radio>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-radio
                      value="Full Sanitization"
                      color="#20a7e0"
                      :class="
                        presentationDetails.sanitizationType ==
                        'Full Sanitization'
                          ? 'radio-background radio-class'
                          : 'radio-class'
                      "
                    >
                      <template v-slot:label>
                        <v-row>
                          <v-col class="col-2 image-container">
                            <img
                              class="image-invite"
                              src="/assets/img/prezentations/full_sanitization.svg"
                              alt
                            />
                          </v-col>
                          <v-col class="col-10">
                            <div class="no-sanitization">
                              {{ $t('upload.fullAnonymization') }}
                            </div>
                            <div class="no-sanitization-label">
                              {{ $t('upload.fullAnonymizationText') }}
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                    </v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <span class="note-message">
              {{ $t('prezentationsList.noteMessage') }}
            </span>
          </v-row>
          <v-row class="send-btn">
            <v-btn
              class="primary-button"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              :loading="loading"
              :disabled="isSendPresentationDisabled()"
              @click="sendPresentation"
              :href="getMailContent()"
            >
              {{ $t('prezentationsList.sendPresentation') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </div>
    <div v-if="step === 3">
      <v-container>
        <v-row class="image-container">
          <img
            class="w-100"
            src="/assets/img/prezentations/success-email.svg"
            alt
          />
        </v-row>
      </v-container>
      <div justify="center" align="center" class="upload-description">
        <div class="description1">
          {{ $t('overnightPresentations.thankYou') }}
        </div>
        <div class="description2">
          {{ $t('overnightPresentations.teamWillReviewAndContact') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getStorylines, uploadRequest } from '../../utils/api-helper';
import NewFeatureBadge from '../common/NewFeatureBadge.vue';
import usersApi from '../../API/users-api';
import { trackPrezentationEvents } from '../common/Analytics/PrezentationEvents';
import {
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_PREZTYPE,
  TD_TEMPLATE,
} from '../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'AddPrezentation',
  components: {
    NewFeatureBadge,
  },
  props: {
    close: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      search: '',
      templateSearch: '',
      presentTypeList: [],
      presentationDetails: {
        storyline: '',
        templateType: {},
        description: '',
        sanitizationType: 'No Sanitization',
      },
      result: {
        id: '',
        name: '',
        presentationName: '',
        presentationType: {
          outline: null,
        },
        primaryAudience: null,
      },
      loading: false,
      isNotValid: true,
      message: '',
      colleaguesList: [
        { email: '', firstname: '', lastname: '', role: '' },
        { email: '', firstname: '', lastname: '', role: '' },
      ],
      failedEntries: [],
      step: 1,
      accessLevel: ['User', 'Admin'],
      themeNamesList: [],
      description: '',
      files: [],
      selectTemplateKey: 0,
      templatesList: [],
      filteredThemes: [],
    };
  },
  async mounted() {
    this.filteredThemes = this.themes.filter((themes) => themes.name !== 'All');
    await this.getStoryLinesAndTheme();
  },
  computed: {
    ...mapState('users', ['currentUser', 'themes']),
    currentThemeCode() {
      return (
        this.currentTheme?.code || this.currentUser?.theme?.code || 'default'
      );
    },
  },
  methods: {
    handleClickTheme(theme) {
      if (theme?.name) {
        this.presentationDetails.templateType.name = theme.name;
      } else {
        this.presentationDetails.templateType = {};
      }
    },
    continueToNext() {
      this.step = 2;
      trackPrezentationEvents.prezentationsAddMoreContinueClick(
        this.currentUser,
      );
    },
    handleClose() {
      this.$modal.hide('AddPrezentation');
    },
    handleContinue() {
      if (this.planLicenseAvailable === 0) {
        this.$emit('addLicence');
      } else {
        this.step = 1;
        if (this.planLicenseAvailable === 1) {
          this.colleaguesList = [
            { email: '', firstname: '', lastname: '', role: '' },
          ];
        } else {
          this.colleaguesList = [
            { email: '', firstname: '', lastname: '', role: '' },
            { email: '', firstname: '', lastname: '', role: '' },
          ];
        }
      }
    },
    handleCustomSelectClick() {
      this.presentationDetails.storyline = 'Custom';
      this.presentationDetails.description = '';
      this.result = this.presentTypeList.find((item) => item.type === 'Custom');
    },
    async getStoryLinesAndTheme() {
      this.presentTypeList = [];
      await Promise.all([
        getStorylines({ addInternal: true, storyType: 'inductive' }),
        getStorylines({ addInternal: true, storyType: 'deductive' }),
      ])
        .then((res) => {
          res.forEach((response, i) => {
            response?.data?.items.forEach((storyline) => {
              if (
                storyline.type === 'Custom' &&
                !storyline.isPrezentGenerated
              ) {
                //
              } else {
                this.presentTypeList.push({
                  ...storyline,
                  storyType: i === 1 ? 'deductive' : 'inductive',
                });
              }
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateDescription(storylineData) {
      if (storylineData?.type) {
        this.presentationDetails.description = storylineData.description;
        this.presentationDetails.storyline = storylineData.type;
      } else {
        this.presentationDetails.description = '';
        this.presentationDetails.storyline = '';
      }
    },
    isSendPresentationDisabled() {
      return (
        !this.presentationDetails.storyline ||
        !this.presentationDetails.templateType?.name ||
        !this.presentationDetails.description ||
        !this.presentationDetails.sanitizationType
      );
    },
    onFileChanged(event) {
      this.files = event.target.files;
      this.files.forEach((file) => {
        const deckId = this.getDeckId(file);
        if (!this.decks.directory[deckId]) {
          const newFile = {
            [deckId]: {
              id: deckId,
              file,
            },
          };
          this.decks.directory = {
            ...this.decks.directory,
            ...newFile,
          };
          this.decks.ids.push(deckId);
        }
      });
    },
    async sendPresentation() {
      try {
        const params = {
          details: {
            storyline: this.presentationDetails?.storyline,
            template: this.presentationDetails?.templateType?.name,
            description: this.presentationDetails?.description,
            sanization: this.presentationDetails?.sanitizationType,
          },
        };
        await uploadRequest(params);
        this.step = 3;
      } catch (err) {
        console.log(err);
      }
      trackPrezentationEvents.prezentationsAddmoreContinueSendPrezentationClick(
        this.currentUser,
        {
          [TD_COMMON_COLUMN_NAME]: 'Sanititzation Type',
          [TD_PREZTYPE]: this.result.type,
          [TD_TEMPLATE]: this.presentationDetails.templateType.name,
          [TD_COMMON_COLUMN_VALUE]: this.presentationDetails.sanitizationType,
        },
      );
    },
    getMailContent() {
      const subject = `New presentation addition request from ${this.currentUser.user.firstName} ${this.currentUser.user.lastName} at ${this.currentUser.company.name}`;
      const body = `Hi Prezent Team, %0D%0A%0D%0A${encodeURIComponent(
        this.currentUser.user.firstName,
      )} ${encodeURIComponent(
        this.currentUser.user.lastName,
      )} (${encodeURIComponent(
        this.currentUser.user.id,
      )}) from ${encodeURIComponent(
        this.currentUser.company.name,
      )} would like the attached documents to be added to the Prezentations library.%0D%0A%0D%0AStoryline - ${encodeURIComponent(
        this.presentationDetails.storyline,
      )}%0D%0ATemplate - ${encodeURIComponent(
        this.presentationDetails.templateType.name,
      )}%0D%0ADescription - ${encodeURIComponent(
        this.presentationDetails.description,
      )}%0D%0ASanitization - ${encodeURIComponent(
        this.presentationDetails.sanitizationType,
      )}%0D%0A%0D%0ARegards,%0D%0APrezent`;
      const mailToContent = `mailto:prezent-product-team@prezent.ai?subject=${subject}&body=${body}`;
      return mailToContent;
    },
    async updateThemeIndex(theme) {
      try {
        await usersApi.methods
          .updateUserProfile({ defaultTheme: theme.code })
          .then(async (updatedUserDetail) => {
            const userInfo = this.currentUser;
            userInfo.user = updatedUserDetail;
            await this.setCurrentUser(userInfo);
          });
        this.setCurrentTheme(theme);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.modal-pad {
  padding: 11px 24px !important;
}
.close-step-2 {
  margin: 5px !important;
}
.modal-container {
  background: white;
  border-radius: 15px;
  position: relative;
  padding: 24px;

  .add-user-title {
    color: $dark-default;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.02em;
  }

  .image-container {
    justify-content: center;
    .d-none {
      display: none;
    }
  }
  .upload-description {
    font-size: 16px;
    line-height: 130%;

    .description1 {
      margin-top: 25px;
      font-weight: 600;
    }
    .description2 {
      margin-top: 6px;
    }
  }
  .float-right {
    margin-top: 5%;
    float: right;
  }

  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 15px;
  }

  .no-more-license {
    font-size: 20px;
    opacity: 0.5;
  }
  .add-btn,
  .col {
    padding: 0;
  }
  .textarea-container {
    width: 100%;
    margin: 20px 0 10px 0;

    .message-label {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: bold;
    }
    .textarea {
      min-width: 100%;

      textarea {
        box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
        padding: 10px;
        height: 75px;
        width: 100%;
      }
    }
  }

  .v-btn--disabled.v-btn--has-bg {
    background-color: #d8d8d8 !important;
    color: 7b7b7b !important;
    font-size: 16px;
    font-weight: 600;
  }

  .image-container {
    text-align: center;
  }
  .image-invite {
    width: 75%;
  }

  .align-center {
    display: flex;
    justify-content: center;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .item-row {
    margin: -6px -12px;
    font-size: 16px;
    align-items: center;
    text-transform: none;
  }

  .image-success-invite {
    width: 40%;
    margin: 20px auto;
  }

  .share-btn {
    margin-top: 10px;
    padding: 6px 24px;
    text-transform: none;
  }
  .add-btn {
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
  }

  .row {
    width: 100%;
    margin: 0%;
  }
  .email-list-container {
    margin-top: 5%;
    padding: 0px;
    .row {
      align-items: center;
      flex-wrap: nowrap;
    }
    .name-col {
      padding: 0;
      min-width: 22%;
      margin-right: 10px;

      .message-label {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .textarea-container {
      width: 100%;
      margin: 0px;

      .message-label {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
      }
      .textarea {
        min-width: 100%;

        textarea {
          box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
          padding: 10px;
          height: 75px;
          width: 100%;
          border-radius: 16px;
        }

        textarea {
          font-size: 16px;
          color: #000000;
          line-height: 20.8px;
        }
        textarea::placeholder {
          font-size: 14px;
        }
        textarea:disabled {
          color: #697077;
        }
      }
    }

    .question-container {
      margin-top: 16px;
      .message-label {
        margin: 0px;
      }
    }
    .email-col {
      margin-right: 10px;
      padding: 0;
      min-width: 32%;

      .message-label {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .role-col {
      margin-right: 10px;
      min-width: 15%;
      padding: 0;

      .message-label {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
      }

      .select-container {
        padding-top: 0px;
      }
    }
    .close-col {
      margin-right: 10px;
      min-width: 5%;
    }

    .image-invite {
      width: 75%;
    }
    .similar-slides-radiobtns {
      .radio-class {
        margin-bottom: 1%;
        padding: 6px;
      }
      .radio-background {
        background-color: #e1f5fb;
        border-radius: 4px;
      }
      .no-sanitization {
        line-height: 19px;
        color: #000000;
        font-weight: 600;
      }
      .no-sanitization-label {
        line-height: 17px;
        margin-top: 0.25rem;
        color: #000000;
        font-size: 14px;
      }
    }
    .send-btn {
      justify-content: flex-end;
      .blue-btn {
        background-color: #21a7e0;
        color: #ffffff;
        font-size: 16px;
        font-weight: none;
      }
      .v-btn {
        text-decoration: none;
      }
    }
  }
  .presentation-type-combobox-wrapper {
    .autocomplete-wrapper {
      max-width: 500px;
      padding-top: 0px !important ;
    }
    .presentation-type-step2 {
      display: flex;
      ::v-deep .v-input {
        margin: 0 !important;
        padding: 0 !important;
        max-width: 85%;
        font-size: 14x;
      }
    }
    .storyline-type {
      padding-top: 5px;
      font-size: 14px;
      padding-right: 7px;
    }
  }
  .option-wrapper {
    display: flex;
    align-items: flex-end;
    .plus-icon {
      font-size: 30px;
      margin-right: 10px;
    }
    .prez-heading__wrapper {
      width: 100% !important;
    }
    .prez-heading,
    .prez-description {
      max-width: 43vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .prez-heading {
      font-size: 14px;
      color: black;
    }
    .prez-description {
      font-size: 12px;
    }
  }
  .prezentaion-list-item--add {
    position: sticky;
    bottom: 0;
    background: white;
    border-top: 1px solid #ccc;

    .v-list-item__title {
      align-items: center;
      display: flex;
      font-size: 14px;

      .add-icon {
        margin-right: 10px;
      }
    }

    .prez-heading-primary {
      justify-content: center;
      font-size: 14px;
      color: #21a7e0;
    }
  }
  .v-list .v-list-item--active {
    color: #21a7e0 !important;
  }
  .v-list .v-list-item--active .v-icon {
    color: rgba(0, 0, 0, 0.87);
  }
  .v-list-item--two-line {
    min-height: unset !important;
  }
  .v-tooltip__content {
    .option-tooltip-wrapper {
      max-width: 43vw;
      .prez-heading {
        font-size: 14px;
        color: black;
      }
      .prez-description {
        font-size: 12px;
        color: #666666;
      }
    }
    .option-tooltip-wrapper__small {
      max-width: 25vw;
      .prez-heading {
        font-size: 14px;
        color: black;
      }
      .prez-description {
        font-size: 12px;
        color: #666666;
      }
      ::v-deep .v-list-item__subtitle,
      .prez-description {
        white-space: normal !important;
      }
    }
  }
}
.v-stepper__wrapper {
  padding-top: 40px;
}
.presentation-type-combobox-wrapper {
  .presentation-type-step2 {
    display: flex;
    ::v-deep .v-input {
      margin: 0 !important;
      padding: 0 !important;
      max-width: 85%;
      font-size: 14x;
    }
  }
  .storyline-type {
    padding-top: 5px;
    font-size: 14px;
    padding-right: 7px;
  }
}
.new-presentation-section {
  display: flex;
  position: relative;
  height: 100%;
}
.template-selector-active {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32%;
  max-width: 500px;
  height: calc(100% - 140px);
  z-index: 15;
}
.step-two-accept__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.theme--light.v-stepper .v-stepper__step__step {
  color: transparent !important;
}
::v-deep .theme--light.v-messages {
  display: none !important;
}

.theme--light.v-stepper .v-stepper__step__step.primary {
  background-color: transparent !important;
  border: 3px solid #6faec3;
}

.theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: #6faec3;
}

.v-stepper__step--complete + .v-divider {
  border-color: $zen-blue-default !important;
}

.theme--light.v-stepper .v-stepper__header .v-divider {
  margin-top: 12px !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.v-divider {
  margin: 35px -76px 0 !important;
  border-width: 1.5px 0 0 0 !important;
}

.v-stepper__step--complete > .v-stepper__label {
  color: #6faec3 !important;
}

.theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: #6faec3;
  font-size: 35px;
  position: relative;
  left: 3px;
  top: -4px;
}

.story-line-list {
  padding-left: 50px;
}
.story-line-list-content {
  max-width: 400px !important;
}
.option-wrapper {
  display: flex;
  align-items: flex-end;
  .plus-icon {
    font-size: 30px;
    margin-right: 10px;
  }
  .prez-heading__wrapper {
    width: 100% !important;
  }
  .prez-heading,
  .prez-description {
    max-width: 43vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .prez-heading {
    font-size: 14px;
    color: black;
  }
  .prez-description {
    font-size: 12px;
  }
}
.v-list .v-list-item--active {
  color: #21a7e0 !important;
}
.v-list .v-list-item--active .v-icon {
  color: rgba(0, 0, 0, 0.87);
}
.v-list-item--two-line {
  min-height: unset !important;
}
.prezentaion-list-item--add {
  position: sticky;
  bottom: 0;
  background: white;
  border-top: 1px solid #ccc;

  .v-list-item__title {
    align-items: center;
    display: flex;
    font-size: 14px;

    .add-icon {
      margin-right: 10px;
    }
  }

  .prez-heading-primary {
    justify-content: center;
    font-size: 14px;
    color: #21a7e0;
  }
}
.v-tooltip__content {
  .option-tooltip-wrapper {
    max-width: 43vw;
    .prez-heading {
      font-size: 14px;
      color: black;
    }
    .prez-description {
      font-size: 12px;
      color: #666666;
    }
  }
  .option-tooltip-wrapper__small {
    max-width: 25vw;
    .prez-heading {
      font-size: 14px;
      color: black;
    }
    .prez-description {
      font-size: 12px;
      color: #666666;
    }
    ::v-deep .v-list-item__subtitle,
    .prez-description {
      white-space: normal !important;
    }
  }
}
.template-list-item {
  padding: 0 16px;
}
.template-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-family: 'Lato';
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  height: 40px;
  margin-top: 10px;
  .template-title {
    color: #212121;
    width: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .current-selection {
    color: #7b7b7b;
    width: 46%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.available-theme-img,
.example-slide-img {
  img {
    box-shadow: 0.413462px 0.413462px 1.65385px rgba(0, 0, 0, 0.1),
      -0.413462px -0.413462px 1.65385px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
}
.no-templates-fount {
  color: #000000;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  .no-result-text {
    border-bottom: 0.8px solid #b6b6b6;
    padding: 20px 0px 20px 20px;
  }
  .add-more-templates-btn {
    cursor: pointer;
    align-items: flex-end;
    display: flex;
    padding: 20px 0px 20px 20px;
    p {
      margin-left: 10px;
      margin-bottom: 2px !important;
    }
  }
}

.templates-dropdown {
  flex: none;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 19px;
  ::v-deep .v-text-field__details {
    display: none;
  }

  ::v-deep .v-input__slot {
    border-bottom: 0.8px solid #b6b6b6;
    box-shadow: none;
    font-family: 'Lato';
  }
  .presentation-type-combobox-wrapper {
    .autocomplete-wrapper {
      max-width: 500px;
      padding-top: 0px !important ;
    }
    .presentation-type-step2 {
      display: flex;
      ::v-deep .v-input {
        margin: 0 !important;
        padding: 0 !important;
        max-width: 85%;
        font-size: 14x;
      }
    }
    .storyline-type {
      padding-top: 5px;
      font-size: 14px;
      padding-right: 7px;
    }
  }
}
.colleague-message {
  color: #697077;
  font-size: 12px;
  margin-bottom: 4px;
}
.note-message {
  color: #697077;
  font-size: 12px;
  margin-bottom: 6px;
}
::v-deep .v-list .v-list-item--active {
  color: #e1f5fb !important;
  background-color: #e1f5fb;
}
</style>
