<template>
  <v-toolbar-items class="top-navbar-icons__wrapper">
    <div
      class="right-nav-item upload"
      v-if="isUploadFullAccess"
      data-pendo-id="upload-prez-icon"
    >
      <v-tooltip
        bottom
        max-width="250"
        content-class="tooltip-content"
        fixed
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            small
            :class="`ma-0 rounded-circle ${isTrialExpired ? 'disabled' : ''}`"
            icon
            :ripple="false"
            :disabled="isTrialExpired"
            @click="onuploadIconClick"
            v-bind="attrs"
            v-on="on"
          >
            <v-img contain alt :src="'/assets/img/upload-cta-nav.svg'"></v-img>
          </v-btn>
        </template>
        <span>Upload</span>
      </v-tooltip>
    </div>
    <div
      class="right-nav-item share-fingerprint-icon"
      data-pendo-id="share-fingerprint-icon"
    >
      <template>
        <v-tooltip
          bottom
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <NewFeatureBadge
              module-name="sharefingerprintsicon"
              :feature-id="'sharefingerprintsicon_id'"
              :offsetX="'27'"
              :offsetY="'40'"
              :size="'small'"
            >
              <v-btn
                depressed
                small
                :class="`ma-0 rounded-circle ${
                  isTrialExpired ? 'disabled' : ''
                }`"
                icon
                :ripple="false"
                :disabled="isTrialExpired"
                @click="openShareFingerprint"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  v-if="isTrialExpired"
                  contain
                  alt
                  :src="'/assets/img/fingerprint-nav-icon-grey.svg'"
                ></v-img>
                <v-img
                  v-else-if="showShareFingerprint"
                  contain
                  alt
                  :src="'/assets/img/fingerprint-nav-icon-selected.svg'"
                ></v-img>
                <v-img
                  v-else
                  contain
                  alt
                  :src="'/assets/img/fingerprint-nav-icon-black.svg'"
                ></v-img>
              </v-btn>
            </NewFeatureBadge>
          </template>
          <span>{{ $t('navbarActions.shareFingerprint') }}</span>
        </v-tooltip>

        <v-menu
          v-model="showShareFingerprint"
          absolute
          offset-y
          :position-x="menuPosition.x"
          :position-y="menuPosition.y"
          :nudge-width="700"
          :close-on-click="closeOnClick"
          :close-on-content-click="false"
        >
          <div v-if="showShareFingerprint">
            <share-finger-print
              type="menu"
              @close="handleShareFingerprintClose"
              @subModalOpen="changeCloseOnClick"
              :origin="shareFingerPrintOrigin"
            />
          </div>
        </v-menu>
      </template>
    </div>

    <div
      class="right-nav-item add-audience-icon"
      data-pendo-id="add-audience-icon"
    >
      <template>
        <v-tooltip
          bottom
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              small
              :class="`ma-0 rounded-circle ${isTrialExpired ? 'disabled' : ''}`"
              icon
              :ripple="false"
              :disabled="isTrialExpired"
              @click="openAddAudienceMenu"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon name="add-audience-icon" :color="colorAddAudience">
                {{ audienceIcon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('fingerprint.searchPage.addNewAudience') }}</span>
        </v-tooltip>

        <v-menu
          v-model="showAddAudience"
          absolute
          offset-y
          :position-x="menuPosition.x"
          :position-y="menuPosition.y"
          :nudge-width="578"
          :close-on-click="closeOnClick"
          :close-on-content-click="false"
        >
          <div v-if="showAddAudience">
            <add-collegue-detail
              :close="closeAddColleague"
              @subModalOpen="changeCloseOnClick"
              trigger="header"
              origin="header"
              id="add-audience-menu"
            />
          </div>
        </v-menu>
      </template>
    </div>

    <div
      class="right-nav-item notification-icon"
      data-pendo-id="notification-icon"
    >
      <template>
        <v-badge
          class="notification-badge"
          :content="getNotificationNotSeen || 0"
          top
          offset-x="13"
          offset-y="13"
          dot
          bordered
          :value="getNotificationNotSeen || 0"
        >
          <v-tooltip
            bottom
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                small
                :class="`ma-0 rounded-circle ${
                  isTrialExpired ? 'disabled' : ''
                }`"
                icon
                :ripple="false"
                :disabled="isTrialExpired"
                @click="openNotificationsMenu"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon name="notification-icon" :color="colorNotifications">
                  {{
                    !isTrialExpired &&
                    notifications &&
                    notifications.length > 0 &&
                    notifications.filter((notif) => notif.seen === false)
                      .length > 0
                      ? newNotificationIcon
                      : generalNotificationIcon
                  }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{
              `${
                notifications &&
                notifications.length > 0 &&
                notifications.filter((notif) => notif.seen === false).length > 0
                  ? `${
                      notifications.filter((notif) => notif.seen === false)
                        .length
                    }${$t('navbarActions.newNotifications')}`
                  : $t('navbarActions.notifications')
              }`
            }}</span>
          </v-tooltip>
        </v-badge>

        <v-menu
          v-model="showNotificationMenu"
          absolute
          :attach="true"
          offset-y
          :position-x="menuPosition.x - 425"
          :position-y="menuPosition.y"
          :nudge-width="575"
          :max-height="
            notifications && notifications.length <= 0
              ? 160
              : notificationsMenuHeight
          "
          :close-on-content-click="false"
          content-class="notificationListMenu"
        >
          <div v-if="loadingNotificationsFlag" class="spinnerContainer">
            <ClipLoader :color="`#21a7e0`" />
            <h2>{{ $t('navbarActions.loadingNotifications') }}</h2>
          </div>
          <div v-else>
            <div v-if="notifications && notifications.length > 0">
              <v-icon
                medium
                class="notificationMenuCloseIcon"
                @click="closeNotificationsMenu"
              >
                mdi-close
              </v-icon>
              <div class="notificationsHeading">
                <div class="notification__title">
                  {{ $t('navbarActions.notifications') }}
                </div>
              </div>
              <v-list
                id="notifications-menu"
                :style="{
                  height: `${
                    notificationsMenuHeight -
                    (notifications && notifications.length > 5 ? 116 : 114)
                  }px`,
                  overflow: 'auto',
                  padding: '0',
                }"
                ref="notificationListRef"
              >
                <v-list-item
                  v-for="(item, index) in notifications"
                  :key="index"
                  :class="
                    index === notifications.length - 1
                      ? 'last-notification-item'
                      : 'notification-item'
                  "
                  style="marign-bottom: 0px !important"
                >
                  <div class="titleAndContainer">
                    <div class="top-panel">
                      <div
                        :class="
                          item.read === false
                            ? 'notification-title'
                            : 'notification-title-when-read'
                        "
                      >
                        {{ item.notification }}
                      </div>
                    </div>
                    <div class="bottom-panel">
                      {{ elapsedTime(item.created_at) }}
                    </div>
                  </div>
                  <div class="readUnreadToggleContainer">
                    <v-btn
                      text
                      icon
                      :color="
                        item.read && item.read === true ? '#b6b6b6' : '#21a7e0'
                      "
                      :class="
                        item.read && item.read === true
                          ? 'markUnreadButton'
                          : 'markReadButton'
                      "
                      @click="
                        handleReadUnreadToggleClick({
                          notifId: item.id,
                          readStatus: item.read,
                          notification: item.notification,
                        })
                      "
                      :id="item.id"
                      v-tooltip="{
                        content: `${
                          item.read === true
                            ? $t('navbarActions.markAsUnread')
                            : $t('navbarActions.markAsRead')
                        }`,
                        placement: `${
                          notifications.length === 1 ? 'left' : 'bottom'
                        }`,
                      }"
                    >
                      <v-icon large> mdi-circle-medium </v-icon>
                    </v-btn>
                  </div>
                </v-list-item>
              </v-list>
            </div>
            <div class="no-notification" v-else>
              <img src="/assets/img/no-notifications.svg" alt />
              <div class="no-notification-title">
                {{ $t('navbarActions.thereAreNoNotifications') }}
              </div>
            </div>
          </div>
        </v-menu>
      </template>
    </div>

    <div
      class="right-nav-item share-free-trial-icon"
      data-pendo-id="share-free-trial-icon"
    >
      <template>
        <v-tooltip
          bottom
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              small
              icon
              :ripple="false"
              @click="openShareMenu"
              :disabled="isTrialExpired"
              :class="`ma-0 rounded-circle ${isTrialExpired ? 'disabled' : ''}`"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon name="top-bar-share-icon" :color="colorShare">
                {{ shareIcon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('common.share') }}</span>
        </v-tooltip>
        <v-menu
          v-model="showShare"
          absolute
          offset-y
          :position-x="menuPosition.x"
          :position-y="menuPosition.y"
          :nudge-width="900"
          :close-on-content-click="false"
          :close-on-click="closeOnClick"
          id="share-free-trail-menu"
        >
          <ShareFreeTrail
            v-if="showShare"
            :close="closeShareMenu"
            origin="top nav bar"
            @subModalOpen="changeCloseOnClick"
          />
        </v-menu>
      </template>
    </div>

    <SwitchLanguage />

    <div class="right-nav-item profile-link" data-pendo-id="profile-link">
      <v-tooltip
        bottom
        max-width="250"
        content-class="tooltip-content"
        fixed
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            name="profile-icon"
            class="profile-image-wrapper"
            v-bind="attrs"
            v-on="on"
            @click="handleClickProfile"
            data-pendo-id="nav-profile-avatar"
          >
            <div
              v-if="profileImage !== '/assets/img/left-nav/profile.svg'"
              class="profile-image-upload"
            >
              <v-img contain :src="profileImage" alt />
            </div>
            <div v-else>
              <v-avatar
                class="profile-user-avatar"
                color="rgb(88, 168, 222)"
                size="24"
                rounded
              >
                <span
                  class="white--text text-body-2"
                  style="color: white; font-weight: 250"
                  >{{ userInitials }}</span
                >
              </v-avatar>
            </div>
          </div>
          <!-- <v-btn
            depressed
            small
            icon
            @click="handleNavClickFn()"
            :ripple="false"
            link
            :to="`/profile`"
            class="ma-0 rounded-circle"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon name="profile-icon" :color="colorProfile">
              {{ profileIcon }}
            </v-icon>
          </v-btn> -->
        </template>
        <span>{{ $t('navbarActions.profile') }}</span>
      </v-tooltip>
    </div>
  </v-toolbar-items>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import moment from 'moment';
import {
  mdiAccountPlusOutline,
  mdiBellOutline,
  mdiGiftOutline,
  mdiAccountOutline,
  // mdiCircleMedium,
  mdiBellBadgeOutline,
} from '@mdi/js';
import { getFileURL } from '@/utils/calyrex';
import {
  // getNotificationsForUser,
  markNotificationsSeen,
  updateNotificationsForUser,
} from '../../utils/api-helper';
import DialogService from '../../services/DialogService';
import EventBus from '../common/event-bus';
// import { updateNotifications } from '../../graphql/mutations';
// import { notificationsForUser } from '../../graphql/queries';

import AddCollegueDetail from '../Main/NewPrezentation/AddCollegueDetail.vue';
import SwitchLanguage from '../SwitchLanguage.vue';

// Material icons imports
import ShareFreeTrail from './ShareFreeTrailNew.vue';
import { MatomoAnalyticsHandler } from '../common/Analytics/MatomoAnalyticsHandler';
import { trackProfileEvents } from '../common/Analytics/ProfileEvents';
import { trackGeneralEvents } from '../common/Analytics/GeneralEvents';
import NewFeatureBadge from '../common/NewFeatureBadge.vue';
import ShareFingerPrint from '../Main/FingerPrints/ShareFingerPrint.vue';
import {
  shareFingerPrintVsPlacement,
  TD_PLACEMENT,
  TD_SHARE_FINGERPRINT_NAVBAR,
} from '../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'NavbarActions',
  mixins: [DialogService],
  components: {
    AddCollegueDetail,
    ShareFreeTrail,
    NewFeatureBadge,
    ShareFingerPrint,
    ClipLoader,
    SwitchLanguage,
  },
  props: {
    updateContent: {
      type: Function,
      default: () => ({}),
    },
    updateMini: {
      type: Function,
      default: () => ({}),
    },
    minimize: {
      type: Function,
      default: () => ({}),
    },
  },

  data() {
    return {
      showShareFingerprint: false,
      showAddAudience: false,
      profileImage: '/assets/img/left-nav/profile.svg',
      profileImageActive: 'assets/img/left-nav/profile-active.svg',
      // Icon definition
      generalNotificationIcon: mdiBellBadgeOutline,
      newNotificationIcon: mdiBellOutline,
      audienceIcon: mdiAccountPlusOutline,
      shareIcon: mdiGiftOutline,
      profileIcon: mdiAccountOutline,
      // readUnredToggleIcon: mdiCircleMedium,

      username: '',
      // isShown: false,
      showMenu: false,
      showNotificationMenu: false,
      showShare: false,
      activeProfile: false,
      closeOnClick: true,
      menuPosition: {
        x: 0,
        y: 0,
      },
      audienceList: [],
      audience: null,
      shareFingerPrintOrigin: TD_SHARE_FINGERPRINT_NAVBAR,
    };
  },
  computed: {
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapGetters('slidesStore', [
      'getIsSlideDetailsDirty',
      'getIsSlideACLDirty',
      'getIsSlideBestPractisesDirty',
    ]),
    ...mapState('tourStore', ['notifications', 'loadingNotificationsFlag']),
    ...mapState('users', [
      'currentUser',
      'isTrialExpired',
      'trialDaysLeft',
      'themes',
      'startLoadingSlidesFlag',
    ]),
    ...mapGetters('users', ['isUploadFullAccess']),
    // ...mapState("users", ["sessionKey"]),
    ...mapState('prezentations', ['allPrezentations']),
    userInitials() {
      if (!this.currentUser) {
        return '';
      }
      let firstLetter = '';
      let secondLetter = '';
      if (
        this.currentUser.user.firstName &&
        this.currentUser.user.firstName.trim().length > 0
      ) {
        firstLetter = this.currentUser.user.firstName.charAt(0).toUpperCase();
      }
      if (
        this.currentUser.user.lastName &&
        this.currentUser.user.lastName.trim().length > 0
      ) {
        secondLetter = this.currentUser.user.lastName.charAt(0).toUpperCase();
      }
      return firstLetter + secondLetter;
    },

    getNotificationNotSeen() {
      return !this.isTrialExpired &&
        this.notifications &&
        this.notifications.length > 0
        ? this.notifications.filter((notif) => notif.seen === false).length
        : 0;
    },

    /*
     * Check if user trial is active and toggle the audience icon color accordingly
     */

    colorAddAudience() {
      let color;
      if (this.isTrialExpired) {
        color = 'lightgrey';
      } else if (this.showAddAudience) {
        color = '#21a7e0';
      } else {
        color = 'black';
      }
      return color;
    },
    colorNotifications() {
      let color;
      if (this.isTrialExpired) {
        color = 'lightgrey';
      } else if (this.showNotificationMenu === true) {
        color = '#21a7e0';
      } else {
        color = 'black';
      }
      return color;
    },

    colorShare() {
      let color;
      if (this.isTrialExpired) {
        color = 'lightgrey';
      } else if (this.showShare) {
        color = '#21a7e0';
      } else {
        color = 'black';
      }
      return color;
    },

    colorUpload() {
      let color;
      if (this.isTrialExpired) {
        color = 'lightgrey';
      } else if (this.showupload) {
        color = '#21a7e0';
      } else {
        color = 'black';
      }
      return color;
    },

    colorProfile() {
      const color =
        !(this.showAddAudience || this.showMenu || this.showShare) &&
        this.activeProfile
          ? '#21a7e0'
          : 'black';
      return color;
    },
    notificationsMenuHeight() {
      if (this.notifications && this.notifications.length <= 5) {
        return this.notifications.length * 90 + 114;
      }
      return 564;
    },

    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },
  },
  watch: {
    showNotificationMenu(newVal, oldVal) {
      if (
        oldVal !== newVal &&
        newVal === false &&
        this.notifications &&
        this.notifications.length > 0 &&
        this.notifications.filter((notif) => notif.seen === false).length > 0
      ) {
        this.markNotificationsSeenOnMenuClose();
      }
    },
  },
  methods: {
    ...mapActions('slidesStore', [
      'setRouteToNavigate',
      'saveSlidesStateSnapshot',
      'setSlideViewConfirmationModalState',
    ]),
    ...mapActions('tourStore', ['setTour']),
    ...mapActions('users', ['setCurrentUser', 'setUploadFlowCTAOrigin']),
    ...mapActions('learndata', ['loadLearningData']),
    ...mapActions('tourStore', [
      'setTour',
      'setNotifications',
      'updateNotificationReadStatus',
      'updateNotificationsSeenStatus',
    ]),

    handleShareFingerprintClose() {
      this.showShareFingerprint = false;
    },
    closeNotificationsMenu() {
      this.showNotificationMenu = false;
    },
    changeCloseOnClick(val) {
      this.closeOnClick = val;
    },
    handleReadUnreadToggleClick({ notifId, readStatus, notification }) {
      console.log(readStatus);
      if (!readStatus) {
        MatomoAnalyticsHandler.trackNotificationRead(
          this.currentUser.user,
          notification,
        );
      }
      if (readStatus) {
        MatomoAnalyticsHandler.trackNotificationUnread(
          this.currentUser.user,
          notification,
        );
      }
      updateNotificationsForUser({ id: notifId, read: !readStatus })
        .then((resp) => {
          this.updateNotificationReadStatus({
            notification: resp.notification,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    markNotificationsSeenOnMenuClose() {
      markNotificationsSeen()
        .then((resp) => {
          console.log(resp);
          this.updateNotificationsSeenStatus();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openShareFingerprint(e) {
      trackGeneralEvents.shareFingerprintIconclick(this.currentUser, {
        [TD_PLACEMENT]:
          shareFingerPrintVsPlacement[TD_SHARE_FINGERPRINT_NAVBAR],
      });
      this.handleNavClickFn();
      this.showShareFingerprint = true;
      this.menuPosition.x = e.clientX;
      if (e.clientY < 60) {
        this.menuPosition.y = 60;
      } else {
        this.menuPosition.y = e.clientY;
      }
    },

    openNotificationsMenu(e) {
      trackGeneralEvents.notificationsIconClick(this.currentUser);
      this.handleNavClickFn();
      this.showNotificationMenu = true;
      this.menuPosition.x = e.clientX;
      if (e.clientY < 60) {
        this.menuPosition.y = 60;
      } else {
        this.menuPosition.y = e.clientY;
      }
    },

    openShareMenu(e) {
      trackGeneralEvents.sharetrialsIconClick(this.currentUser);
      this.handleNavClickFn();
      this.showShare = true;
      this.menuPosition.x = e.clientX;
      if (e.clientY < 60) {
        this.menuPosition.y = 60;
      } else {
        this.menuPosition.y = e.clientY;
      }
    },
    closeShareMenu() {
      this.showShare = false;
    },

    closeAddColleague() {
      this.showAddAudience = false;
    },
    openAddAudienceMenu(e) {
      this.showAddAudience = true;
      this.menuPosition.x = e.clientX;
      if (e.clientY < 60) {
        this.menuPosition.y = 60;
      } else {
        this.menuPosition.y = e.clientY;
      }
    },
    onuploadIconClick() {
      this.setUploadFlowCTAOrigin('upload_navbar');
      this.$router.push('/home/upload');
    },
    elapsedTime(time) {
      return moment(time).fromNow();
    },
    currentRoute() {
      return this.$route.path;
    },

    getProfileImage() {
      if (this.currentUser.user.profileImage) {
        getFileURL(
          null,
          `public/${this.currentUser.user.profileImage}`,
          null,
          'storage',
        )
          .then((s3obj) => {
            this.profileImage = s3obj;
          })
          .catch((err) => {
            console.log(err);
            this.profileImage = '/assets/img/home-header-profile.svg';
          });
      } else {
        this.profileImage = '/assets/img/left-nav/profile.svg';
      }
    },
    handleClickProfile() {
      this.activeProfile = true;
      this.isProfileClick = !this.isProfileClick;
      if (
        (this.getIsDirty === true ||
          this.getIsDirtyIcon === true ||
          this.getIsSlideDetailsDirty === true ||
          this.getIsSlideACLDirty === true ||
          this.getIsSlideBestPractisesDirty === true) &&
        (this.$route.name === 'slide-detail' || this.isBPLSlideView) &&
        this.startLoadingSlidesFlag &&
        this.themes &&
        this.themes.length > 0
      ) {
        this.setSlideViewConfirmationModalState(true);
        this.setRouteToNavigate({
          content: { name: 'profile' },
          type: 'to_profile',
          action: 'replace',
          pushAction: false,
        });
      } else {
        this.saveSlidesStateSnapshot({});
        this.setRouteToNavigate({
          content: '',
          type: '',
          action: '',
          pushAction: false,
        });
        this.$router.replace({ name: 'profile' }).catch(() => {});
      }
      trackProfileEvents.profileIconClick(this.currentUser);

      // clearing global search value when this component is clicked after making a global search
      EventBus.$emit('CLEAR_SEARCH');
      // this.$router.push('/home/profile').catch(() => {});
    },
    handleClickShare() {
      this.isShareClick = !this.isShareClick;
    },
    handleClickAudience() {
      this.isAudienceClick = !this.isAudienceClick;
    },
    handleNavClickFn() {
      EventBus.$emit('CLEAR_SEARCH');
    },
    serializeParam(params) {
      return Object.entries({ ...params })
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    },
    setLabel(item) {
      return item.name;
    },
    getLearnLevel(id) {
      const parts = id.split('_');
      const number = parts[1];
      const level = number.slice(0, 2);
      return Math.floor((parseInt(level, 10) - 1) / 6);
    },
    getLearnModule(id) {
      const parts = id.split('_');
      const number = parts[1];
      const item = number.slice(0, 2);
      return parseInt(item, 10) - this.getLearnLevel(id) * 6;
    },
    handleAudienceSelect() {
      this.$router.push(`/home/fingerprints?audience=${this.audience}`);
    },
    beforeClose(event) {
      if (!event.params?.close) {
        event.cancel();
      }
    },
  },
  async beforeMount() {
    // this.isShown = this.notifications && this.notifications.length > 0;
    this.getProfileImage();
    // let payload = {
    //   userID: this.currentUser.id,
    //   organizationID: this.currentUser.organizationID,
    // };
    // this.loadLearningData(payload);
  },
  beforeDestroy() {
    EventBus.$off('PROFILE_IMAGE', this.getProfileImage);
    EventBus.$off('MINIMIZE_LEFTNAV', this.minimize);
  },
  mounted() {
    EventBus.$on('PROFILE_IMAGE', this.getProfileImage);
    EventBus.$on('MINIMIZE_LEFTNAV', this.minimize);
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.top-navbar-icons__wrapper {
  align-items: center;
}
.profile-image-wrapper {
  border-radius: 50%;
  border: 1px solid white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}
.profile-image-upload {
  height: 24px;
  width: 24px;
}
.profile-user-avatar {
  height: 29px;
  min-width: 24px;
  width: 24px;
}
.profile-link {
  .v-btn--active.v-btn--active {
    color: $zen-blue-default !important;
  }
}
.right-nav-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 60px;
}

.disabled {
  pointer-events: none;
}

.disable-events {
  pointer-events: none;
}
.share-confirmation {
  .email {
    color: #20a7e0;
    font-size: 16px;
  }

  .share-confirmation-message {
    font-size: 16px;
  }
}

.share-container {
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-around;
  margin-bottom: 20px;

  .content {
    font-size: 20px;
    font-weight: bold;
  }
  .active {
    border-bottom: 6px solid #20a7e0;
  }
}
.v-list-item {
  padding-left: 12px;
  padding-right: 8px;
  height: 90px;
}
.top-header-wrapper {
  height: 68px;
}
.topnav-wrapper {
  box-shadow: -2px 2px 25px 1px rgba(0, 0, 0, 0.2);
}

.top-bar-burger {
  margin-right: 0px;
}

.v-menu__content {
  width: 268px;
  right: 20px;
  left: 0px;
  background: white;
  padding: 20px;
  border-radius: 15px;
  top: 60px;
}

.notificationListMenu {
  right: 20px;
  left: 0px;
  background: white;
  padding: 20px 0px 35px 0px;
  border-radius: 15px;
  top: 60px;
  .spinnerContainer {
    text-align: center;
    .spinner {
      margin-bottom: 16px;
    }
    h2 {
      font-size: 20px;
      font-weight: 500;
    }
  }
  .notificationMenuCloseIcon {
    margin-left: 93%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .notificationsHeading {
    .notification__title {
      letter-spacing: -0.01em;
      padding: 15px 0px 15px 36px;
      font-family: 'Lato' !important;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }
  }
  #notifications-menu {
    .notification-item,
    .last-notification-item {
      display: flex;
      padding-bottom: 0px;
      margin: 0px 24px 0px 24px;
      .titleAndContainer {
        width: 94%;
        margin-right: 8px;
        height: 100%;
        .top-panel {
          height: 75%;
          .notification-title {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            padding-top: 24px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
          }
          .notification-title-when-read {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            padding-top: 24px;
            color: #b6b6b6;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
          }
        }
        .bottom-panel {
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          color: #b6b6b6;
          height: 25%;
        }
      }
      .readUnreadToggleContainer {
        width: 6%;
        cursor: pointer;
        height: 100%;
        padding-top: 16px;
        button {
          width: 36px;
          height: 36px;
        }
      }
      &:hover {
        background-color: #e1f4fd;
      }
    }
    .notification-item {
      border-bottom: 1px solid #d8d8d8;
    }
  }
}

.no-notification {
  text-align: center !important;
  &::after {
    content: ' ';
    position: absolute;
    right: 16px;
    top: 4px;
    border-top: 5px solid transparent;
    border-right: 15px solid white;
    border-left: none;
    border-bottom: 15px solid transparent;
    box-sizing: border-box;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -2px 4px 4px 0 rgb(0 0 0 / 20%);
    text-align: center !important;
    justify-content: center;
  }
  img {
    margin-bottom: 10px;
  }

  .no-notification-title {
    font-size: 16px;
  }
}
</style>
