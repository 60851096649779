var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-container"},[_c('v-btn',{staticClass:"close-btn",attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"dark":""},domProps:{"textContent":_vm._s("mdi-close")}})],1),_c('div',[_c('span',{staticClass:"add-user-title"},[_vm._v(_vm._s(_vm.$t('profile.addUsersToTeam')))])]),(_vm.step === 1)?_c('div',[_c('v-container',[_c('v-row',[_c('img',{staticClass:"image-invite",attrs:{"src":"/assets/img/profile/add-multiple-users.svg","alt":""}})])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.isNotValid),callback:function ($$v) {_vm.isNotValid=$$v},expression:"isNotValid"}},[_c('v-container',{staticClass:"email-list-container"},[_c('v-row',[_c('v-col',{class:[
              'name-col',
              _vm.isTrial ? 'min-width-trial' : 'min-width-default' ]},[_c('div',{staticClass:"message-label"},[_vm._v(_vm._s(_vm.$t('profile.firstname')))])]),_c('v-col',{class:[
              'name-col',
              _vm.isTrial ? 'min-width-trial' : 'min-width-default' ]},[_c('div',{staticClass:"message-label"},[_vm._v(_vm._s(_vm.$t('profile.lastname')))])]),_c('v-col',{staticClass:"email-col"},[_c('div',{staticClass:"message-label"},[_vm._v(" "+_vm._s(_vm.$t('navbarActions.addColleagueDetail.email'))+" ")])]),(!_vm.isTrial)?_c('v-col',{staticClass:"role-col"},[_c('div',{staticClass:"message-label"},[_vm._v(_vm._s(_vm.$t('profile.role')))])]):_vm._e(),_c('v-col',{staticClass:"close-col"})],1),_vm._l((_vm.colleaguesList),function(colleague,index){return _c('v-row',{key:index},[_c('v-col',{class:[
              'name-col',
              _vm.isTrial ? 'min-width-trial' : 'min-width-default' ]},[_c('v-text-field',{attrs:{"rules":_vm.firstnameRequired,"placeholder":_vm.$t('profile.firstname'),"required":"","id":'name-input-' + index},model:{value:(_vm.colleaguesList[index].firstname),callback:function ($$v) {_vm.$set(_vm.colleaguesList[index], "firstname", $$v)},expression:"colleaguesList[index].firstname"}})],1),_c('v-col',{class:[
              'name-col',
              _vm.isTrial ? 'min-width-trial' : 'min-width-default' ]},[_c('v-text-field',{attrs:{"rules":_vm.lastnameRequired,"placeholder":_vm.$t('profile.lastname'),"required":"","id":'lastname-input-' + index},model:{value:(_vm.colleaguesList[index].lastname),callback:function ($$v) {_vm.$set(_vm.colleaguesList[index], "lastname", $$v)},expression:"colleaguesList[index].lastname"}})],1),_c('v-col',{staticClass:"email-col"},[_c('v-text-field',{attrs:{"rules":_vm.colleaguesList[index].existError &&
                _vm.colleaguesList[index].existError.length > 0
                  ? []
                  : _vm.emailRules,"placeholder":_vm.$t('navbarActions.shareFreeTrial.workEmail'),"required":"","id":'email-input-' + index,"loading":_vm.colleaguesList[index].loading,"error-messages":_vm.colleaguesList[index].existError &&
                _vm.colleaguesList[index].existError.length > 0
                  ? _vm.colleaguesList[index].existError
                  : ''},on:{"keyup":function (value) { return _vm.handleEmailChange(value, index); },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addColleague('enter')}},model:{value:(_vm.colleaguesList[index].email),callback:function ($$v) {_vm.$set(_vm.colleaguesList[index], "email", $$v)},expression:"colleaguesList[index].email"}})],1),(!_vm.isTrial)?_c('v-col',{staticClass:"role-col colleague-role"},[_c('v-select',{attrs:{"rules":_vm.userRoleRequired,"items":_vm.accessLevel,"item-text":"name","placeholder":_vm.$t('profile.colleagueRole'),"label":_vm.$t('profile.select'),"return-object":"","single-line":"","id":'role-input-' + index,"attach":"","menu-props":index === _vm.colleaguesList.length - 1
                  ? { top: true, offsetY: false }
                  : { top: false, offsetY: false }},model:{value:(_vm.colleaguesList[index].role),callback:function ($$v) {_vm.$set(_vm.colleaguesList[index], "role", $$v)},expression:"colleaguesList[index].role"}})],1):_vm._e(),_c('v-col',{staticClass:"close-col"},[(_vm.colleaguesList.length > 1)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeColleague(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1):_vm._e()],1)],1)})],2),_c('v-container',[(_vm.licenseAvailable)?_c('v-row',[_c('v-btn',{staticClass:"add-btn",attrs:{"text":"","color":"primary","disabled":_vm.colleaguesList.length >= 10},on:{"click":_vm.addColleague}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('profile.addColleague'))+" ")],1)],1):_c('v-row',{staticClass:"no-more-license"},[_vm._v(" "+_vm._s(_vm.$t('profile.youHaveOnly'))+" "+_vm._s(_vm.planLicenseAvailable)+" "+_vm._s(_vm.$t('profile.licensesAvailable'))+" ")]),_c('v-row',[_c('v-col',{staticClass:"textarea-container",attrs:{"cols":"12"}},[_c('div',{staticClass:"message-label"},[_vm._v(_vm._s(_vm.$t('profile.message')))]),_c('div',{staticClass:"textarea"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],attrs:{"placeholder":_vm.$t('profile.addANote')},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}})])])],1),_c('v-row',[_c('v-btn',{staticClass:"share-btn primary-button",attrs:{"rounded":"","color":"#21a7e0","height":"48","min-width":"134","dark":"","loading":_vm.loading,"disabled":!_vm.isNotValid ||
              _vm.colleaguesList.every(function (colleague) {
                return (
                  colleague.email == '' ||
                  colleague.firstname == '' ||
                  colleague.lastname == '' ||
                  colleague.role == ''
                );
              })},on:{"click":_vm.onAddUser}},[_vm._v(" "+_vm._s(_vm.$t('navbarActions.shareFreeTrial.sendEmail'))+" ")])],1)],1)],1)],1):_vm._e(),(_vm.step === 2)?_c('div',[_c('v-container',[_c('v-row',{staticClass:"align-center"},[_c('img',{staticClass:"image-success-invite",attrs:{"src":"/assets/img/profile/email-sent.svg","alt":""}})])],1),_c('v-container',[_c('div',{staticClass:"email-send"},[_vm._v(" "+_vm._s(_vm.$t('profile.addedNewUsersAndEmailsSent'))+" ")]),(_vm.failedEntries.length)?_c('div',{staticClass:"failed-users"},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('profile.unableToAddUsers'))+" ")]),_c('div',{staticClass:"users-list"},_vm._l((_vm.failedEntries),function(entry,index){return _c('p',{key:index},[_c('span',[_vm._v(_vm._s(entry.firstName))]),_c('span',[_vm._v(_vm._s(entry.lastName))]),_c('span',[_vm._v("("+_vm._s(entry.id)+")")])])}),0)]):_vm._e(),_c('div',{staticClass:"add-more-users-btn"},[_c('v-btn',{staticClass:"share-btn primary-button",attrs:{"rounded":"","color":"#21a7e0","height":"48","min-width":"134"},on:{"click":_vm.handleContinue}},[_vm._v(" "+_vm._s(_vm.$t('profile.addMoreUsers'))+" ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }